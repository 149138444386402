import { SelectItem } from "primeng/api";
import { userInfos } from "src/app/components/activities/shared-data";

export const userItems: SelectItem[] = Object.values(userInfos)
  .sort((a, b) => a.fullName.localeCompare(b.fullName)) // Sort by fullName
  .map(user => ({
    label: user.fullName,
    value: user.id
  }));

export const userFirstNameItems: SelectItem[] = Object.values(userInfos)
  .sort((a, b) => a.firstName.localeCompare(b.fullName)) // Sort by fullName
  .map(user => ({
    label: user.firstName,
    value: user.id
  }));

export const storeItems: SelectItem[] = [
  { label: "Fælles lager", value: 1052 },
  ...Object.values(userInfos)
    .sort((a, b) => a.fullName.localeCompare(b.fullName)) // Sort by fullName
    .map(user => ({
      label: user.firstName,
      value: user.id
    }))
];

export const userItemsTrialPeriod: SelectItem[] = Object.values(userInfos)
  .filter(user => user.trialPeriod === true)
  .sort((a, b) => a.trialPeriodSort - b.trialPeriodSort)
  .map(user => ({
    label: user.fullName,
    value: user.id
  }));

export const userItemsTrialPeriodFirstName: SelectItem[] = Object.values(userInfos)
  .filter(user => user.trialPeriod === true)
  .sort((a, b) => a.trialPeriodSort - b.trialPeriodSort)
  .map(user => ({
    label: user.firstName,
    value: user.id
  }));

// Define user-specific items with "Alle" option
export const userItemsWithAll: SelectItem[] = [{ label: "Alle", value: -1 }, ...userItems];

// Define user-specific items with "None" option
export const userItemsWithAllAndNone: SelectItem[] = [{ label: "Ingen", value: -2 }, ...userItemsWithAll];

export const userItemsWithNotSelected: SelectItem[] = [{ label: "Ikke valgt", value: 0 }, ...userItems];

export const statusFilterItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Telekontakt", label: "Telekontakt" },
  { value: "Salgsmøde", label: "Salgsmøde" },
  { value: "Praktik", label: "Praktik" },
  { value: "Prøveperiode", label: "Prøveperiode" },
  { value: "Kunde", label: "Kunde" },
  { value: "Agromek", label: "Agromek" },
  { value: "Afvist", label: "Afvist" },
  { value: "OphoertIChr", label: "Ophørt ifølge CHR" },
  { value: "UgyldigeOplysninger", label: "Ugyldige kontaktoplysninger" }
];

export const kanbanStatusItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "ToDo", label: "To Do" },
  { value: "InProgress", label: "In Progress" },
  { value: "ReadyForTest", label: "Ready for Test" },
  { value: "Done", label: "Done" }
];

export const kanbanTypeItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Task", label: "Task" },
  { value: "Bug", label: "Bug" },
  { value: "Feature", label: "Feature" },
  { value: "Improvement", label: "Improvement" }
];

export const kanbanPriorityItems: SelectItem[] = [
  { value: "", label: "Ikke angivet" },
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" }
];

export const mejeriItems: Array<SelectItem> = [
  { value: "", label: "Ikke angivet" },
  { value: "Andre", label: "Andre" },
  { value: "Arla", label: "Arla" },
  { value: "Naturmælk", label: "Naturmælk" },
  { value: "Thise", label: "Thise" }
];

export const farmTypeItems: Array<SelectItem> = [
  { value: "Malkekvægsbesætning", label: "Normal" },
  { value: "Økologisk malkekvægsbesætning", label: "Økologisk" }
];

export const malkesystemItems: Array<SelectItem> = [
  { value: "", label: "Ikke angivet" },
  { value: "Andre robotter", label: "Andre robotter" },
  { value: "Andre karrusel", label: "Andre karrusel" },
  { value: "DeLaval karrusel", label: "DeLaval karrusel" },
  { value: "DeLaval robotter ", label: "DeLaval robotter" },
  { value: "GEA robotter", label: "GEA robotter" },
  { value: "GEA karrusel", label: "GEA karrusel" },
  { value: "Lely robotter", label: "Lely robotter" },
  { value: "SAC karrusel", label: "SAC karrusel" },
  { value: "SAC robotter", label: "SAC robotter" }
];

export const trialPeriodStatusItems: Array<SelectItem> = [
  { value: null, label: "Ikke angivet", title: "lightgrey" },
  { value: "Aftalt", label: "Aftalt", title: "lightgreen" },
  { value: "Reserveret", label: "Reserveret", title: "lightblue" },
  { value: "Forslag", label: "Forslag", title: "#FFA07A" }, // Lys orange
  { value: "Datoaendres", label: "Dato ændres", title: "lightyellow" },
  { value: "Aflyst", label: "Aflyst", title: "#FF7F7F" } // Lys rød
];

export const danishWeekdays: Array<SelectItem> = [
  { value: 1, label: "Mandag" },
  { value: 2, label: "Tirsdag" },
  { value: 3, label: "Onsdag" },
  { value: 4, label: "Torsdag" },
  { value: 5, label: "Fredag" },
  { value: 6, label: "Lørdag" },
  { value: 7, label: "Søndag" }
];
