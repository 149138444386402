import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { ProspectTrialPeriodDto } from "@apiModels/prospectTrialPeriodDto";
import { TrialPeriodPlanDto } from "@apiModels/trialPeriodPlanDto";
import { WeekYearDto } from "@apiModels/weekYearDto";
import { ProspectTrialPeriodDtoExt } from "@globals/ExtModels/ProspectTrialPeriodDtoExt";
import { ActivityService } from "@globals/services/activity.service";
import { danishWeekdays, trialPeriodStatusItems, userItemsTrialPeriod } from "@shared/interfaces-and-enums/shared-data";

@Component({
  selector: "app-prospect-trialperiod-scheduler",
  templateUrl: "./prospect-trialperiod-scheduler.component.html",
  styleUrls: ["./prospect-trialperiod-scheduler.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectTrialperiodSchedulerComponent {
  @Input() year: number | null = null;
  @Input() week: number | null = null;
  @Output() prospectIdSelected = new EventEmitter<number>(); // prospectId

  userItems = [{ label: "Ukendt", value: null }, ...userItemsTrialPeriod];
  statuses = trialPeriodStatusItems;
  weeks: { weekNumber: string; trialPeriods: ProspectTrialPeriodDtoExt[] }[] = [];
  danishWeekdays = danishWeekdays;

  constructor(
    private activityService: ActivityService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activityService.getTrialPeriodPlan().subscribe((data: TrialPeriodPlanDto) => {
      if (data?.prospectTrialPeriods && data?.weekYears) {
        this.generateWeeks(data.prospectTrialPeriods, data.weekYears);
        this.cd.markForCheck();
      }
    });
  }

  private generateWeeks(prospectTrialPeriods: ProspectTrialPeriodDto[], weekYears: WeekYearDto[]): void {
    const minWeekYear = Math.min(...prospectTrialPeriods.map(p => p.trialPeriodStartWeekYearId || 0));
    const maxWeekYear = Math.max(...prospectTrialPeriods.map(p => p.trialPeriodStartWeekYearId || 0));

    const allWeekYears = weekYears.filter(weekYear => weekYear.id && weekYear.id >= minWeekYear && weekYear.id <= maxWeekYear);

    const extendedProspects = prospectTrialPeriods.map(p => ({
      ...p,
      employee: this.getEmployeeName(p.trialPeriodUserIds)
    })) as ProspectTrialPeriodDtoExt[];

    const groupedByWeekYear = allWeekYears.reduce(
      (acc, weekYear) => {
        const weekData = extendedProspects.filter(p => p.trialPeriodStartWeekYearId === weekYear.id);

        if (!acc[weekYear.id!]) {
          acc[weekYear.id!] = {
            weekNumber: `Uge ${weekYear.week}, ${weekYear.year}`,
            trialPeriods: []
          };
        }

        weekData.forEach(trialPeriod => {
          const userIds = trialPeriod.trialPeriodUserIds ? trialPeriod.trialPeriodUserIds.split(",").map(id => Number(id.trim())) : [0];
          userIds.forEach(userId => {
            const user = this.userItems.find(u => u.value === userId);
            if (user) {
              acc[weekYear.id!].trialPeriods.push({
                ...trialPeriod,
                employee: user.label
              });
            }
          });
        });

        return acc;
      },
      {} as Record<number, { weekNumber: string; trialPeriods: ProspectTrialPeriodDtoExt[] }>
    );

    allWeekYears.forEach(weekYear => {
      if (!groupedByWeekYear[weekYear.id!]) {
        groupedByWeekYear[weekYear.id!] = {
          weekNumber: `Uge ${weekYear.week}, ${weekYear.year}`,
          trialPeriods: []
        };
      }
    });

    this.weeks = Object.values(groupedByWeekYear).sort((a, b) => {
      const aId = parseInt(a.weekNumber.split(", ")[1], 10) * 100 + parseInt(a.weekNumber.split(" ")[1], 10);
      const bId = parseInt(b.weekNumber.split(", ")[1], 10) * 100 + parseInt(b.weekNumber.split(" ")[1], 10);
      return aId - bId;
    });

    // Identify employees present in the data
    const usedEmployeeLabels = new Set(extendedProspects.map(prospect => prospect.employee).filter(employee => employee !== "Ukendt"));

    // Filter userItems to only include relevant employees
    this.userItems = this.userItems.filter(user => usedEmployeeLabels.has(user.label));
  }

  getEmployeeName(userIds: string | null): string {
    if (!userIds) return "Ukendt"; // Håndter null eller undefined

    const userIdArray = userIds.split(",").map(id => id.trim()); // Fjern ekstra mellemrum
    const employee = this.userItems.find(emp => userIdArray.includes(emp.value?.toString()));

    return employee ? employee.label : "Ukendt";
  }

  getAppointmentsForEmployeeAndWeek(week: any, employee: any) {
    return week.appointments.filter((appt: ProspectTrialPeriodDtoExt) => appt.employee === employee.label);
  }

  getAppointmentColor(status: string): string {
    const statusObj = this.statuses.find(s => s.value === status);
    return statusObj ? statusObj.title : "#ccc";
  }

  getFormattedNotes(trialPeriodNotes: string): string {
    return trialPeriodNotes?.replace(/\n/g, "\n") || "";
  }

  getDayOfWeekLabel(day: number): string {
    return danishWeekdays[day]?.label || null;
  }

  selectProspect(prospectId: number): void {
    this.prospectIdSelected.emit(prospectId);
  }
}
