import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProspectTrialPeriodDto } from "@apiModels/prospectTrialPeriodDto";
import { ActivityService } from "@globals/services/activity.service";
import { userItemsTrialPeriodFirstName } from "@shared/interfaces-and-enums/shared-data";
import moment from "moment";
import { SelectItem } from "primeng/api";

export interface TrialPeriodDay {
  id: number;
  typeId: number; // 1 = Teori, 2 = Praksis, 3 = Kontakt og 4 = Opfølgning
  userIds: number[];
  notes: string;
  datePlannedStartUtc?: string;
  datePlannedEndUtc?: string;
  plannedStartDate: Date;
  plannedStartTime: string;
  plannedEndDate: Date;
  plannedEndTime: string;
  activityId?: number | null;
}

@Component({
  selector: "app-trail-period-detail",
  templateUrl: "./trail-period-detail.component.html",
  styleUrls: ["./trail-period-detail.component.scss"]
})
export class TrialPeriodDetailComponent implements OnInit {
  @Input() trialPeriodId?: number;
  @Input() trialPeriod?: ProspectTrialPeriodDto;
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(
    private activityService: ActivityService,
    private cd: ChangeDetectorRef
  ) {}

  trialPeriodDays: TrialPeriodDay[] = [];
  timeIntervals: SelectItem[] = [];
  weekOptions = [1, 2];
  dayOptions = ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag"];
  calendarDialogVisible = false;

  lessonTypes = [
    { id: 1, name: "Teori" },
    { id: 2, name: "Praktisk" },
    { id: 0, name: "Egen hånd" },
    { id: 3, name: "Eval og afslut" },
    { id: 4, name: "Opfølgning" }
  ];

  lessonPlan = [
    { dayOffset: 0, typeId: 1, note: "Der er kun teori denne dag!" }, // Teori med test torsdag
    { dayOffset: 4, typeId: 2 }, // Praktisk undervisning mandag
    { dayOffset: 5, typeId: 2 }, // Praktisk undervisning tirsdag
    { dayOffset: 6, typeId: 0, note: "Vi vil være i telefonisk kontakt efterfølgende" }, // Egen hånd (opfølgning telefonisk) onsdag
    { dayOffset: 7, typeId: 2 }, // Praktisk undervisning torsdag
    { dayOffset: 8, typeId: 2, note: "Vi evaluerer ugen der er gået. " }, // Praktisk undervisning fredag
    { dayOffset: 11, typeId: 0 }, // Laser anvendes på egen hånd mandag
    { dayOffset: 12, typeId: 0 }, // Laser anvendes på egen hånd tirsdag
    { dayOffset: 13, typeId: 3, note: "Vi evaluerer hele forløbet og afslutter hos jer" }, // Evaluering og afslutning onsdag
    { dayOffset: 25, typeId: 4 } // Opfølgning
  ];

  // Exclude specific user IDs from the user list
  userItems = userItemsTrialPeriodFirstName;
  selectedUserItems: SelectItem[] = [];

  ///availableUsers = [1, 2, 3, 4, 5];

  ngOnInit(): void {
    this.initializeTimeIntervals();
    this.initializeTrialPeriodDays();
  }

  getWeekday(date: Date): string {
    const weekdays = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"];
    return weekdays[new Date(date).getDay()];
  }

  getDateOfISOWeek(week, year, weekday = 4) {
    const simple = new Date(year, 0, 1 + (week - 1) * 7); // Første dag i ugen
    const dayOfWeek = simple.getDay(); // Ugedagen (0 = Søndag, 1 = Mandag osv.)
    const ISOWeekStart = simple;

    if (dayOfWeek <= 4) {
      ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1); // Første dag i ugen (Mandag)
    } else {
      ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay()); // Første dag i næste uge
    }

    // Juster til ønsket dag i ugen (4 = Torsdag)
    ISOWeekStart.setDate(ISOWeekStart.getDate() + (weekday - 1));

    return ISOWeekStart;
  }

  initializeTrialPeriodDays(): void {
    if (this.trialPeriodId && !this.trialPeriod) {
      this.activityService.getTrialPeriod(this.trialPeriodId).subscribe(
        response => {
          this.trialPeriod = response;
          this.initializeTrialPeriodDays();
        },
        error => {
          console.error("Error fetching trial period:", error);
        }
      );
      return;
    }

    if (this.trialPeriod && this.trialPeriod.days && this.trialPeriod.days.length > 0) {
      // Brug de eksisterende dage fra TrialPeriod
      this.trialPeriodDays = this.trialPeriod.days.map(day => {
        const startDate = new Date(moment(day.datePlannedStartUtc).toLocaleString());

        const endDate = new Date(moment(day.datePlannedEndUtc).toLocaleString());

        return {
          id: day.id,
          typeId: day.typeId,
          userIds: day.userIds ? day.userIds.split(",").map(id => parseInt(id)) : [],
          notes: day.notes,
          datePlannedStartUtc: startDate ? startDate.toISOString() : null,
          datePlannedEndUtc: endDate ? endDate.toISOString() : null,
          plannedStartDate: startDate,
          plannedStartTime: startDate ? `${startDate.getHours().toString().padStart(2, "0")}:${startDate.getMinutes().toString().padStart(2, "0")}` : "10:00",
          plannedEndDate: endDate,
          plannedEndTime: endDate ? `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}` : "12:00",
          activityId: day.activityId
        };
      });
    } else {
      // Default dage, hvis ingen eksisterer
      //const today = new Date();

      let startHour = 10;
      if (this.trialPeriod.trialPeriodStartHour) {
        startHour = this.trialPeriod.trialPeriodStartHour;
      }

      const weekYear = this.trialPeriod.trialPeriodStartWeekYearId; // yyyyWW
      const year = Math.floor(weekYear / 100); // Ekstrakter år (de første 4 cifre)
      const week = weekYear % 100; // Ekstrakter uge (de sidste 2 cifre som tal)
      const today = this.getDateOfISOWeek(week, year, 4);

      let id = 0;
      this.lessonPlan.forEach(lesson => {
        id--;
        const startDate = new Date(today);
        const endDate = new Date(today);

        // Juster start- og sluttid baseret på offset
        startDate.setDate(today.getDate() + lesson.dayOffset);
        startDate.setHours(startHour, 0, 0);

        endDate.setDate(today.getDate() + lesson.dayOffset);
        endDate.setHours(startHour + 2, 0, 0);

        this.trialPeriodDays.push({
          id: id,
          typeId: lesson.typeId,
          userIds: this.trialPeriod.trialPeriodUserIds ? this.trialPeriod.trialPeriodUserIds.split(",").map(id => parseInt(id)) : [],
          notes: lesson.note || "",
          datePlannedStartUtc: startDate.toISOString(),
          datePlannedEndUtc: endDate.toISOString(),
          plannedStartDate: startDate,
          plannedStartTime: `${startHour}:00`,
          plannedEndDate: endDate,
          plannedEndTime: `${startHour + 2}:00`
        });
      });

      console.log("Trial Period Days:", this.trialPeriodDays);
    }
  }

  initializeTimeIntervals(): void {
    for (let i = 0; i < 24 * 2; i++) {
      const hours = Math.floor(i / 2)
        .toString()
        .padStart(2, "0");
      const minutes = i % 2 === 0 ? "00" : "30";
      this.timeIntervals.push({ label: `${hours}:${minutes}`, value: `${hours}:${minutes}` });
    }
  }

  // Metode til at skifte brugerens udvælgelse
  toggleUserSelection(day: TrialPeriodDay, userId: number): void {
    const index = day.userIds?.indexOf(userId);
    if (index !== -1 && index !== undefined) {
      // Fjern brugeren, hvis de allerede er valgt
      day.userIds.splice(index, 1);
    } else {
      // Tilføj brugeren, hvis de ikke er valgt
      if (!day.userIds) {
        day.userIds = [];
      }
      day.userIds.push(userId);
    }
  }

  setLessonType(day: TrialPeriodDay, typeId: number): void {
    day.typeId = typeId;
  }

  getLessonTypeName(typeId: number): string {
    const lessonType = this.lessonTypes.find(type => type.id === typeId);
    return lessonType ? lessonType.name : "Ukendt";
  }

  updateTime(day: TrialPeriodDay, field: "datePlannedStartUtc" | "datePlannedEndUtc", value: string): void {
    const currentDate = new Date(day[field]);
    const [hours, minutes] = value.split(":").map(Number);

    currentDate.setHours(hours, minutes, 0, 0); // Opdater kun timer og minutter
    day[field] = currentDate.toISOString(); // Opdater dato-tidstrengen
  }

  deleteDay(day: TrialPeriodDay): void {
    const index = this.trialPeriodDays.findIndex(d => d === day);
    if (index !== -1) {
      this.trialPeriodDays.splice(index, 1); // Fjern dagen fra arrayet
      this.cd.detectChanges(); // Tving opdatering af UI
      console.log("Dag slettet:", day);
    } else {
      console.error("Kunne ikke finde dagen til sletning:", day);
    }
  }

  get sortedTrialPeriodDays(): TrialPeriodDay[] {
    return [...this.trialPeriodDays].sort((a, b) => {
      const dateDifference = a.plannedStartDate.getTime() - b.plannedStartDate.getTime();
      if (dateDifference !== 0) {
        return dateDifference; // Sorter efter dato, hvis datoerne er forskellige
      }
      return a.typeId - b.typeId; // Hvis datoerne er ens, sorter efter typeId
    });
  }

  getDaysDifference(index: number): number {
    if (this.sortedTrialPeriodDays.length === 0 || index === 0) {
      return 1; // Første dag er altid dag 1
    }

    const firstDay = this.sortedTrialPeriodDays[0].plannedStartDate;
    const currentDay = this.sortedTrialPeriodDays[index].plannedStartDate;

    // Beregn forskellen i dage
    const differenceInMs = currentDay.getTime() - firstDay.getTime();
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    return differenceInDays + 1; // Inkluderer første dag som dag 1
  }

  onDateChange(): void {
    this.trialPeriodDays = [...this.trialPeriodDays].sort((a, b) => a.plannedStartDate.getTime() - b.plannedStartDate.getTime());
  }

  onSave(): void {
    if (this.trialPeriod) {
      this.trialPeriod.days = this.trialPeriodDays.map(day => {
        let plannedStartString = null;
        let plannedEndString = null;

        // Kombiner Start Dato og Tid
        if (day.plannedStartDate) {
          const plannedDate = new Date(day.plannedStartDate); // Samme dato for både start og slut
          if (day.plannedStartTime) {
            const [startHours, startMinutes] = day.plannedStartTime.split(":").map(num => parseInt(num, 10));
            plannedDate.setHours(startHours, startMinutes, 0, 0);
            plannedStartString = plannedDate.toISOString(); // Kombiner starttidspunkt
          }

          // Kombiner Slut Tid med samme dato
          if (day.plannedEndTime) {
            const plannedEndDate = new Date(day.plannedStartDate); // Brug samme dato
            const [endHours, endMinutes] = day.plannedEndTime.split(":").map(num => parseInt(num, 10));
            plannedEndDate.setHours(endHours, endMinutes, 0, 0);
            plannedEndString = plannedEndDate.toISOString(); // Kombiner sluttidspunkt
          }
        }

        // Validering: Sluttidspunkt skal være senere end starttidspunkt
        if (plannedStartString && plannedEndString) {
          const plannedStartDate = new Date(plannedStartString);
          const plannedEndDate = new Date(plannedEndString);

          if (plannedEndDate < plannedStartDate) {
            console.error("Error: Sluttidspunkt skal være senere end starttidspunkt.");
            throw new Error("Ugyldigt tidsinterval for en dag i prøveperioden.");
          }
        }

        return {
          ...day,
          userIds: day.userIds.join(","), // Konverter userIds til en kommasepareret streng
          datePlannedStartUtc: plannedStartString,
          datePlannedEndUtc: plannedEndString,
          notes: day.notes,
          activityId: day.activityId
        };
      });

      this.activityService.updateTrialPeriod(this.trialPeriod).subscribe(
        response => {
          this.trialPeriod = response;

          console.log("Trial Period saved or updated successfully:", response);
          this.closeDialog.emit(true);
        },
        error => {
          console.error("Error saving trial period:", error);
        }
      );
    } else {
      console.error("TrialPeriod data is missing.");
    }
  }

  onCancel(): void {
    this.closeDialog.emit(false);
  }

  onCloseWindow(): void {
    this.closeDialog.emit(false);
  }
  // saveTrialPeriod() {}

  addNewDay(typeId: number): void {
    // Find den sidste post med samme typeId
    let referenceDay = this.trialPeriodDays
      .slice()
      .reverse()
      .find(day => day.typeId === typeId);

    // Hvis ingen findes, brug den sidste post i arrayet
    if (!referenceDay && this.trialPeriodDays.length > 0) {
      referenceDay = this.trialPeriodDays[this.trialPeriodDays.length - 1];
    }

    if (!referenceDay) {
      console.error("Ingen poster tilgængelige at kopiere.");
      return;
    }

    // Lav en ny post baseret på referenceDay
    const newDay: TrialPeriodDay = {
      ...referenceDay,
      id: 0,
      plannedStartDate: new Date(referenceDay.plannedStartDate.getTime() + 24 * 60 * 60 * 1000), // Tilføj 1 dag
      plannedEndDate: new Date(referenceDay.plannedEndDate.getTime() + 24 * 60 * 60 * 1000), // Tilføj 1 dag til slutdato
      typeId: typeId // Sæt den ønskede type
    };

    // Tilføj den nye post til arrayet
    this.trialPeriodDays = [...this.trialPeriodDays, newDay];

    // Sortér listen efter dato og typeId
    this.trialPeriodDays.sort((a, b) => {
      const dateDifference = a.plannedStartDate.getTime() - b.plannedStartDate.getTime();
      if (dateDifference !== 0) {
        return dateDifference;
      }
      return a.typeId - b.typeId; // Sorter efter typeId, hvis datoerne er ens
    });

    // Opdater UI
    this.cd.detectChanges();
  }
}
