<p-accordion>
  <p-accordionTab header="Filtrer">
    <div class="flex-container">
      <div class="p-field">
        <label for="ansvarlig">Ansvarlig: </label>
        <p-multiSelect
          optionLabel="label"
          dataKey="value"
          [options]="userFilterItems"
          [(ngModel)]="selectedUserItems"
          [showToggleAll]="true"
          defaultLabel="Vælg..."
          [selectedItemsLabel]="selectedUserItems?.length === userFilterItems?.length ? 'Alle' : '{0} valgt'"
        >
        </p-multiSelect>
      </div>

      <div class="p-field">
        <label for="status">Status: </label>
        <p-multiSelect
          optionLabel="label"
          dataKey="value"
          [options]="statusFilterItems"
          [(ngModel)]="selectedStatusItems"
          [showToggleAll]="true"
          defaultLabel="Vælg..."
          [selectedItemsLabel]="selectedStatusItems?.length === statusFilterItems?.length ? 'Alle' : '{0} valgt'"
        >
        </p-multiSelect>
      </div>

      <div class="p-field">
        <label for="Type">Type: </label>
        <p-multiSelect
          optionLabel="label"
          dataKey="value"
          [options]="farmTypeItems"
          [(ngModel)]="selectedFarmTypeItems"
          [showToggleAll]="true"
          defaultLabel="Vælg..."
          [selectedItemsLabel]="selectedFarmTypeItems?.length === farmTypeItems?.length ? 'Alle' : '{0} valgt'"
        >
        </p-multiSelect>
      </div>

      <div class="p-field">
        <label for="Mejeri">Mejeri: </label>
        <p-multiSelect
          optionLabel="label"
          dataKey="value"
          [options]="mejeriItems"
          [(ngModel)]="selectedMejeriItems"
          [showToggleAll]="true"
          defaultLabel="Vælg..."
          [selectedItemsLabel]="selectedMejeriItems?.length === mejeriItems?.length ? 'Alle' : '{0} valgt'"
        >
        </p-multiSelect>
      </div>

      <div class="p-field">
        <label for="fraAntalKøer">Fra køer: </label>
        <p-inputNumber [(ngModel)]="fraKoer"></p-inputNumber>
      </div>

      <div class="p-field">
        <label for="tilAntalKøer">Til køer: </label>
        <p-inputNumber [(ngModel)]="tilKoer"></p-inputNumber>
      </div>

      <div class="p-field">
        <button pButton type="button" (click)="handleFilterChange()">Filtrer</button>
        <button pButton class="ml-2" type="button" (click)="resetFilter(true)">Nulstill filter</button>
        <button pButton class="ml-2" type="button" (click)="removeFilter(true)">Fjern filter</button>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="filteredProspects"
        [columns]="cols"
        [globalFilterFields]="cols"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedProspect"
        dataKey="id"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[10, 25, 50]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Prospekter (bedrifter)</h5>
            <!-- <button pButton pRipple label="Opret ny kunde" icon="pi pi-plus" class="p-button-success mr-2" (click)="onCreateNewProspect()"></button> -->
            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Rediger</th>
            <th *ngIf="!showNotInHubspot">Kort</th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
              <div class="flex">
                <div>
                  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="openFarmDialog(rowData)"></button>
                </div>
              </div>
            </td>
            <td *ngIf="!showNotInHubspot">
              <div class="flex">
                <div>
                  <button pButton pRipple icon="pi pi-map-marker" class="p-button-rounded p-button-success mr-2" (click)="showOnMap(rowData)"></button>
                </div>
              </div>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="farmDialogVisible"
  [header]="'Rediger farm' | translate"
  [(visible)]="farmDialogVisible"
  [modal]="true"
  (onHide)="farmDialogVisible = false"
  [style]="{ width: '95vw', minHeight: '90vh' }"
  [maximizable]="true"
  (farmDialogVisibleChange)="farmDialogVisible = false"
>
  <app-prospect-detail [prospectId]="selectedFarmId" (closeDialog)="handleCloseDialog($event)" (farmDialogVisibleChange)="farmDialogVisible = false"></app-prospect-detail>
</p-dialog>
