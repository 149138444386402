import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { ProspectDto } from "@apiModels/prospectDto";
import { ProspectDetailEmailListComponent } from "./prospect-detail-emaillist/prospect-detail-emaillist.component";

@Component({
  selector: "app-prospect-detail",
  templateUrl: "./prospect-detail.component.html",
  styleUrls: ["./prospect-detail.component.scss"]
})
export class ProspectDetailsComponent {
  @ViewChild(ProspectDetailEmailListComponent) emailListComponent: ProspectDetailEmailListComponent;
  @Input() prospectId: number;
  @Output() closeDialog = new EventEmitter<ProspectDto | null>();

  public activeIndex: number = 0;

  onTabChange(event: any) {
    this.activeIndex = event.index;
    if (event.index === 4 && this.emailListComponent) {
      // 4 = Emails-fanen
      this.emailListComponent.refreshEmails(); // Hent e-mails ved skift
    }
  }

  handleCloseDialog(company: ProspectDto | null) {
    this.closeDialog.emit(company);
  }

  onHide() {
    this.closeDialog.emit(null);
  }
}
