import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ConversationRoomDto } from "@apiModels/conversationRoomDto";
import { ConversationService } from "@globals/services/conversation.service";
import { UserService } from "@globals/services/user.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-farmer-dashboard",
  templateUrl: "./farmer-dashboard.component.html",
  styleUrls: ["./farmer-dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FarmerDashboardComponent implements OnInit {
  public availableRooms: ConversationRoomDto[] = []; // Available rooms
  public currentRoom: ConversationRoomDto | null = null; // Current room joined
  public isSearching: boolean = false; // Searching state
  public joinStatus: "idle" | "searching" | "joined" = "idle"; // Join status

  public userid: number | -1;

  constructor(
    private conversationService: ConversationService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    const currentUser = this.userService.getCurrentStateValue().currentUser;
    this.userid = currentUser ? currentUser.id : null;
  }

  ngOnInit(): void {
    this.loadJitsiScript()
      .then(() => {
        console.log("Jitsi script loaded successfully.");
        this.checkCurrentRoom();
      })
      .catch(error => {
        console.error("Failed to load Jitsi script:", error);
      });
  }

  loadJitsiScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="https://meet.jit.si/external_api.js"]`)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = "https://meet.jit.si/external_api.js";
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject("Could not load Jitsi script");
      document.body.appendChild(script);
    });
  }

  // Check current room from server
  checkCurrentRoom(): void {
    this.conversationService.getAllRooms().subscribe(
      rooms => {
        const activeRoom = rooms.find(room => room.userId === this.userid);
        if (activeRoom) {
          this.currentRoom = activeRoom;
          this.joinStatus = "joined";
          this.initiateVideoCall(activeRoom);
        } else {
          this.searchAvailableRooms();
        }
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to fetch rooms: ${error.message}`
        });
      }
    );
  }

  // Search for available rooms
  searchAvailableRooms(): void {
    this.isSearching = true;
    this.conversationService.getAllRooms().subscribe(
      rooms => {
        this.availableRooms = rooms.filter(room => room.isActive && !room.userId); // Find rooms with no active user
        this.joinStatus = this.availableRooms.length > 0 ? "idle" : "searching";
        this.cd.detectChanges();
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to fetch available rooms: ${error.message}`
        });
      },
      () => (this.isSearching = false)
    );
  }

  // Join the first available room
  joinRoom(): void {
    if (this.currentRoom) {
      this.messageService.add({
        severity: "warn",
        summary: "Already Active",
        detail: "You are already in a conversation. Please leave the current room first."
      });
      return;
    }

    if (this.availableRooms.length === 0) {
      this.messageService.add({
        severity: "info",
        summary: "No Rooms Available",
        detail: "There are no available veterinarians at the moment. Please try again later."
      });
      return;
    }

    const room = this.availableRooms[0];
    const updatedRoom: ConversationRoomDto = {
      ...room,
      userId: this.userid, // Assign the current farmer to the room
      dateLastUpdatedUtc: new Date().toISOString()
    };

    this.conversationService.updateRoom(updatedRoom).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Room Joined",
        detail: "You have successfully joined a conversation with a veterinarian."
      });
      this.currentRoom = updatedRoom;
      this.joinStatus = "joined";
      this.initiateVideoCall(room);
    });
  }

  initiateVideoCall(room: ConversationRoomDto): void {
    this.conversationService.generateJaaSToken(`room-${room.doctorId}`, "Farmer", false).subscribe(
      token => {
        console.log("Joining room with token:", token);
        this.joinStatus = "joined";
        this.cd.detectChanges(); // Ensure the DOM is updated

        // Wait for the container to be rendered in the DOM
        setTimeout(() => {
          const container = document.getElementById("jitsi-container");
          if (!container) {
            console.error("Jitsi container not found!");
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Video container is missing or not properly initialized."
            });
            return;
          }

          const options = {
            roomName: `vpaas-magic-cookie-67c319b07a0d4f1bb6954f8689924aaa/room-${room.doctorId}`,
            jwt: token,
            width: "100%",
            height: 600,
            parentNode: container,
            userInfo: {
              displayName: "Farmer"
            },
            configOverwrite: {
              prejoinPageEnabled: false
            },
            interfaceConfigOverwrite: {
              TOOLBAR_BUTTONS: ["microphone", "camera", "hangup", "chat", "settings"],
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
            }
          };

          try {
            const api = new (window as any).JitsiMeetExternalAPI("8x8.vc", options);

            api.addEventListener("videoConferenceJoined", () => {
              console.log("Video conference started.");
            });

            api.addEventListener("videoConferenceLeft", () => {
              console.log("Video conference ended.");
              this.leaveRoom();
            });
          } catch (error) {
            console.error("Failed to initialize Jitsi Meet:", error);
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Failed to initialize the video call."
            });
          }
        }, 0); // Short delay to ensure DOM update
      },
      error => {
        console.error("Failed to generate token:", error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to join video call: ${error.message}`
        });
      }
    );
  }

  // Leave the current room
  leaveRoom(): void {
    if (!this.currentRoom) {
      this.messageService.add({
        severity: "warn",
        summary: "No Active Room",
        detail: "You are not currently in any room."
      });
      return;
    }

    const updatedRoom: ConversationRoomDto = {
      ...this.currentRoom,
      userId: null, // Remove the farmer from the room
      dateLastUpdatedUtc: new Date().toISOString()
    };

    this.conversationService.updateRoom(updatedRoom).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Room Left",
        detail: "You have successfully left the conversation."
      });
      this.currentRoom = null;
      this.joinStatus = "idle";
      this.searchAvailableRooms();
    });
  }
}
