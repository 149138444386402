<p-dropdown [options]="availabilityOptions" placeholder="Filtrer efter tilgængelighed" (onChange)="filterByAvailability($event.value)"> </p-dropdown>

<p-progressSpinner *ngIf="loading"></p-progressSpinner>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns"
        [globalFilterFields]="globalFilterFields"
        [loading]="loading"
        dataKey="id"
        csvSeparator=";"
        [exportFilename]="'loanable-materials-export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Værktøjs udlån</h5>

            <button pButton pRipple label="Eksporter" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <button pButton pRipple label="Opret nyt værktøj" icon="pi pi-plus" class="p-button-success" (click)="addNewLoanableMaterial()"></button>

            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
            <th>Handlinger</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              <ng-container>
                {{ rowData[col.field] }}
              </ng-container>
            </td>
            <td>
              <button pButton class="mr-2" type="button" label="Rediger" icon="pi pi-pencil" (click)="editLoanableMaterial(rowData)"></button>

              <button [disabled]="!rowData.isAvailable" pButton class="mr-2" type="button" label="Udlån Kunde" icon="pi pi-user" (click)="openLoanCustomerDialog(rowData)"></button>

              <button [disabled]="!rowData.isAvailable" pButton class="mr-2" type="button" label="Udlån Ansat" icon="pi pi-money-bill" (click)="openLoanEmployeeDialog(rowData)"></button>

              <button [disabled]="rowData.isAvailable" pButton class="mr-2" type="button" label="Aflevering" icon="pi pi-replay" (click)="openReturnDialog(rowData)"></button>

              <button [disabled]="!rowData.isAvailable" pButton class="mr-2" type="button" label="Skift lager" icon="pi pi-globe" (click)="openMoveDialog(rowData)"></button>

              <button pButton type="button" label="Historik" icon="pi pi-history" (click)="openTransactionDialog(rowData)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog [(visible)]="editDialogVisible" [modal]="true" [closable]="false" [resizable]="false" header="Rediger værktøj" [style]="{ width: '800px', height: '800px' }">
  <form [formGroup]="loanableMaterialForm">
    <div class="p-fluid">
      <div class="p-field">
        <label for="name">Navn:</label>
        <input id="name" pInputText formControlName="name" placeholder="Indtast navn" />
      </div>

      <div class="p-field">
        <label for="description">Beskrivelse:</label>
        <textarea id="description" pInputTextarea formControlName="description" placeholder="Indtast beskrivelse"></textarea>
      </div>

      <div class="p-field">
        <label for="serialNumber">Serienummer:</label>
        <input id="serialNumber" pInputText formControlName="serialNumber" placeholder="Indtast serienummer" />
      </div>

      <div class="mt-2" *ngIf="isEditing" class="p-field">
        <label class="mt-2" for="serialNumber">Nuværende placering:</label>
        <label class="ml-2">{{ selectedItem?.location }}</label>
      </div>

      <div class="p-field-checkbox">
        <label for="isActive"> Aktiv </label>
        <p-checkbox class="ml-2" formControlName="isActive" binary="true" inputId="isActive"></p-checkbox>
      </div>
    </div>
  </form>

  <p-footer>
    <button pButton label="Gem" (click)="saveLoanableMaterial()" class="p-button-primary"></button>
    <button pButton label="Annuller" (click)="editDialogVisible = false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="customerLoanDialogVisible" [header]="'Udlån til Kunde'" [style]="{ width: '800px', height: '800px' }">
  <!-- Dropdown for selecting customer -->
  <div class="p-field mr-2">
    <label for="customer">Vælg Kunde:</label>
    <p-dropdown
      [options]="customerItems"
      [(ngModel)]="selectedLocationCustomerItem"
      optionLabel="label"
      dataKey="value"
      placeholder="Vælg kunde som skal låne værktøjet"
      filter="true"
      filterPlaceholder="Søg kunde"
    ></p-dropdown>
  </div>

  <div class="p-field">
    <label for="comments">Bemærkninger:</label>
    <textarea id="comments" pInputTextarea [(ngModel)]="comments" placeholder="Bemærkninger"></textarea>
  </div>

  <p-footer>
    <button pButton label="Gem" (click)="saveCustomerLoan()" class="p-button-primary"></button>
    <button pButton label="Annuller" (click)="customerLoanDialogVisible = false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="employeeLoanDialogVisible" [header]="'Udlån til Ansat'" [style]="{ width: '800px', height: '800px' }">
  <div class="p-field mr-2">
    <label for="user">Vælg Bruger:</label>
    <p-dropdown
      [options]="userItems"
      [(ngModel)]="selectedLocationUserItem"
      optionLabel="label"
      dataKey="value"
      placeholder="Vælg bruger som skal låne værktøjet"
      filter="true"
      filterPlaceholder="Søg bruger"
    ></p-dropdown>
  </div>

  <div class="p-field">
    <label for="comments">Bemærkninger:</label>
    <textarea id="comments" pInputTextarea [(ngModel)]="comments" placeholder="Bemærkninger"></textarea>
  </div>

  <p-footer>
    <button pButton label="Gem" (click)="saveEmployeeLoan()" class="p-button-primary"></button>
    <button pButton label="Annuller" (click)="employeeLoanDialogVisible = false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="returnDialogVisible" [header]="'Aflevering hos Ansat'" [style]="{ width: '800px', height: '500px' }">
  <div class="p-field mr-2">
    <label for="user">Vælg Ansat:</label>
    <p-dropdown
      [options]="userItems"
      [(ngModel)]="selectedLocationUserItem"
      optionLabel="label"
      dataKey="value"
      placeholder="Vælg ansat som modtager værktøjet"
      filter="true"
      filterPlaceholder="Søg ansat"
    ></p-dropdown>
  </div>

  <div class="p-field">
    <label for="comments">Bemærkninger:</label>
    <textarea id="comments" pInputTextarea [(ngModel)]="comments" placeholder="Bemærkninger"></textarea>
  </div>

  <p-footer>
    <button pButton label="Aflever" (click)="processReturn()" class="p-button-primary"></button>
    <button pButton label="Annuller" (click)="returnDialogVisible = false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="moveDialogVisible" [header]="'Flyt placering til Ansat/lager'" [style]="{ width: '800px', height: '800px' }">
  <div class="p-field mr-2">
    <label for="user">Vælg ny placering:</label>
    <p-dropdown
      [options]="storeItems"
      [(ngModel)]="selectedLocationUserItem"
      optionLabel="label"
      dataKey="value"
      placeholder="Vælg ny ansat eller lager som placering"
      filter="true"
      filterPlaceholder="Søg ansat"
    ></p-dropdown>
  </div>

  <div class="p-field">
    <label for="comments">Bemærkninger:</label>
    <textarea id="comments" pInputTextarea [(ngModel)]="comments" placeholder="Bemærkninger"></textarea>
  </div>

  <p-footer>
    <button pButton label="Flyt" (click)="processMove()" class="p-button-primary"></button>
    <button pButton label="Annuller" (click)="moveDialogVisible = false" class="p-button-secondary"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="transactionDialogVisible" [header]="'Transaktioner for ' + (selectedItem?.name || '')" [style]="{ width: '1200px', height: '800px' }" (onHide)="closeTransactionDialog()">
  <p-table [value]="transactionList" [paginator]="true" [rows]="25" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>Dato</th>
        <th>Type</th>
        <th>Fra</th>
        <th>Til</th>
        <th>Bemærkninger</th>
        <th>Afleveret</th>
        <!-- NEW COLUMN -->
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-tx>
      <tr>
        <!-- Original Transaction Date/Time Column -->
        <td>{{ tx.transactionDateUtc | date: "dd-MM-yyyy HH:mm" }}</td>

        <!-- Transaction Type Column -->
        <td>{{ getTransactionTypeText(tx.transactionTypeId) }}</td>

        <!-- "Fra" (From) Column -->
        <td>
          <ng-container *ngIf="tx.fromUser; else fromCustomer">
            {{ tx.fromUser }}
          </ng-container>
          <ng-template #fromCustomer>
            <ng-container *ngIf="tx.fromCustomer; else noneFrom">
              {{ tx.fromCustomer }}
            </ng-container>
            <ng-template #noneFrom>-</ng-template>
          </ng-template>
        </td>

        <!-- "Til" (To) Column -->
        <td>
          <ng-container *ngIf="tx.toUser; else toCustomer">
            {{ tx.toUser }}
          </ng-container>
          <ng-template #toCustomer>
            <ng-container *ngIf="tx.toCustomer; else noneTo">
              {{ tx.toCustomer }}
            </ng-container>
            <ng-template #noneTo>-</ng-template>
          </ng-template>
        </td>

        <!-- Comments Column -->
        <td>{{ tx.comments }}</td>

        <!-- NEW: Afleveret Column -->
        <td>
          <ng-container *ngIf="tx.returnDateUtc; else noReturn">
            Afleveret {{ tx.returnDateUtc | date: "dd-MM-yyyy HH:mm" }} - {{ computeDaysOut(tx.transactionDateUtc, tx.returnDateUtc) }} dage
          </ng-container>
          <ng-template #noReturn>-</ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-footer>
    <button pButton type="button" label="Luk" icon="pi pi-times" (click)="transactionDialogVisible = false"></button>
  </p-footer>
</p-dialog>
