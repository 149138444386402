import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-prospect-trialperiod-dialog",
  templateUrl: "./prospect-trialperiod-dialog.component.html",
  styleUrls: ["./prospect-trialperiod-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectTrialperiodDialogComponent implements OnInit {
  farmDialogVisible: boolean = false;
  selectedFarmId: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params["id"];
      if (id) {
        this.selectedFarmId = +id;
        this.farmDialogVisible = true;
      } else {
        this.farmDialogVisible = false;
      }
    });
  }

  onNewProspectIdSelected(prospectId: number): void {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`/trialperiodscheduler`, prospectId]);
    });
  }

  handleCloseDialog(updatedFarm: any): void {
    if (updatedFarm) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([`/trialperiodscheduler`]);
      });
    }
    this.farmDialogVisible = false;
  }
}
