<div>
  <button (click)="onSave()">Gem</button>
  <button (click)="onCancel()">Annuller</button>
  <button (click)="addNewDay(2)">Tilføj ny praksis dag</button>
  <button (click)="addNewDay(3)">Tilføj ny opfølgning dag</button>
</div>

<div *ngFor="let day of sortedTrialPeriodDays; let i = index">
  <table>
    <tr>
      <!-- Første celle: Dag-info og slet-knap -->
      <td style="vertical-align: top; position: relative">
        <p-button icon="pi pi-trash" label="" class="p-button-danger" (click)="deleteDay(day)"></p-button>
        <h3>
          Dag {{ getDaysDifference(i) }}<br /><br />
          {{ getLessonTypeName(day.typeId) }}<br /><br />
          {{ getWeekday(day.plannedStartDate) }}
        </h3>
      </td>
      <td>
        <div class="p-field col-12">
          <label for="lessonType-{{ i }}">Type:</label>
          <div class="day-selector">
            <p-button
              *ngFor="let type of lessonTypes"
              [label]="type.name"
              [icon]="day.typeId === type.id ? 'pi pi-check' : null"
              [severity]="day.typeId === type.id ? 'success' : 'secondary'"
              (click)="setLessonType(day, type.id)"
            >
            </p-button>
          </div>
        </div>

        <div class="p-field col-12">
          <label for="users">Brugere</label>
          <div class="day-selector">
            <p-button
              *ngFor="let user of userItems"
              [label]="user.label"
              [icon]="day.userIds?.includes(user.value) ? 'pi pi-check' : null"
              [severity]="day.userIds?.includes(user.value) ? 'success' : 'secondary'"
              (click)="toggleUserSelection(day, user.value)"
            >
            </p-button>
          </div>
          <!-- Error message for no users selected -->
          <small *ngIf="day.userIds?.length === 0" class="p-error">Vælg mindst 1 person</small>
        </div>

        <!-- Planlagt dato -->
        <div class="field col-12">
          <div class="followup-container">
            <div class="date-time-wrapper">
              <!-- Start Dato -->
              <div class="date-time-item">
                <label for="plannedStartDay-{{ i }}" class="bold-label">Start Dato</label>
                <p-calendar id="plannedStartDay-{{ i }}" [(ngModel)]="day.plannedStartDate" (ngModelChange)="onDateChange()" dateFormat="dd-mm-yy" placeholder="Vælg startdato"></p-calendar>
              </div>

              <!-- Start Tid -->
              <div class="date-time-item">
                <label for="plannedStartTime-{{ i }}" class="bold-label">Start Tid</label>
                <p-dropdown id="plannedStartTime-{{ i }}" [(ngModel)]="day.plannedStartTime" [options]="timeIntervals" placeholder="Vælg starttidspunkt"></p-dropdown>
              </div>

              <!-- Slut Tid -->
              <div class="date-time-item">
                <label for="plannedEndTime-{{ i }}" class="bold-label">Slut Tid (ikke med i mail)</label>
                <p-dropdown id="plannedEndTime-{{ i }}" [(ngModel)]="day.plannedEndTime" [options]="timeIntervals" placeholder="Vælg sluttidspunkt"></p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <label for="noter-{{ i }}">Noter (med i mail)</label>
        <textarea rows="10" cols="50" pInputTextarea [(ngModel)]="day.notes"></textarea>
      </td>
    </tr>
  </table>
</div>

<!-- Buttons -->
<div>
  <button (click)="onSave()">Gem</button>
  <button (click)="onCancel()">Annuller</button>
  <button (click)="addNewDay(2)">Tilføj en praksis dag</button>
  <button (click)="addNewDay(4)">Tilføj en opfølgnings dag</button>
  <button (click)="calendarDialogVisible = true">Tilføj en opfølgnings dag</button>
</div>

<p-dialog
  *ngIf="calendarDialogVisible"
  [header]="'Rediger farm' | translate"
  [(visible)]="calendarDialogVisible"
  [modal]="true"
  [style]="{ width: '95vw', minHeight: '90vh' }"
  [maximizable]="true"
  (onHide)="calendarDialogVisible = false"
>
  <app-trial-period-calendar [TrialPeriodId]="2983" [trialPeriodDays]="sortedTrialPeriodDays" (closeDialog)="handleCloseDialog($event)"></app-trial-period-calendar>
</p-dialog>
