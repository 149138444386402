import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ConversationLogDto } from "@apiModels/conversationLogDto";
import { ConversationRoomDto } from "@apiModels/conversationRoomDto";
import { ConversationService } from "@globals/services/conversation.service";
import { UserService } from "@globals/services/user.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-veterinarian-dashboard",
  templateUrl: "./veterinarian-dashboard.component.html",
  styleUrls: ["./veterinarian-dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VeterinarianDashboardComponent implements OnInit {
  public isAvailable: boolean = false; // Availability status
  public activeRooms: ConversationRoomDto[] = []; // Active rooms
  public currentConversation: ConversationRoomDto | null = null; // Selected conversation
  public conversationNotes: string = ""; // Notes for the conversation

  public userid: number | -1;

  private api: any; // Jitsi API instance

  constructor(
    private conversationService: ConversationService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    const currentUser = this.userService.getCurrentStateValue().currentUser;
    this.userid = currentUser ? currentUser.id : null;
  }

  ngOnInit(): void {
    this.loadJitsiScript()
      .then(() => {
        console.log("Jitsi script loaded successfully.");
        this.loadStatusAndRooms();
      })
      .catch(error => {
        console.error("Failed to load Jitsi script:", error);
      });
  }

  loadJitsiScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="https://meet.jit.si/external_api.js"]`)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = "https://meet.jit.si/external_api.js";
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject("Could not load Jitsi script");
      document.body.appendChild(script);
    });
  }

  loadStatusAndRooms(): void {
    this.conversationService.getAllRooms().subscribe(rooms => {
      this.activeRooms = rooms.filter(room => room.doctorId === this.userid && room.isActive);
      this.isAvailable = this.activeRooms.length > 0;

      const activeRoom = this.activeRooms.find(room => room.userId);
      if (activeRoom) {
        this.currentConversation = activeRoom;
        this.initiateVideoCall(activeRoom);
      }
      this.cd.detectChanges();
    });
  }

  toggleAvailability(): void {
    this.isAvailable = !this.isAvailable;

    const roomDto: ConversationRoomDto = {
      id: 0, // For new rooms
      doctorId: this.userid,
      isActive: this.isAvailable,
      userId: null,
      dateLastUpdatedUtc: new Date().toISOString()
    };

    this.conversationService.updateRoom(roomDto).subscribe(() => {
      console.log(`Availability set to: ${this.isAvailable}`);
      if (this.isAvailable) {
        // Start video call immediately when available
        const room: ConversationRoomDto = {
          doctorId: this.userid,
          isActive: true,
          userId: null,
          dateLastUpdatedUtc: new Date().toISOString()
        };
        this.initiateVideoCall(room); // Start the video room
      } else {
        this.disposeVideoCall(); // Stop video when unavailable
      }
      this.loadStatusAndRooms();
    });
  }

  joinConversation(room: ConversationRoomDto): void {
    if (this.currentConversation) {
      this.messageService.add({
        severity: "warn",
        summary: "Active Conversation",
        detail: "You are already in a conversation. Please finish it before joining another."
      });
      return;
    }

    this.currentConversation = room;

    this.conversationService.generateJaaSToken(`room-${room.doctorId}`, "Veterinarian", true).subscribe(token => {
      console.log("Joining room with token:", token);
      this.initiateVideoCall(room, token);
    });
  }

  initiateVideoCall(room: ConversationRoomDto, token?: string): void {
    setTimeout(() => {
      const container = document.getElementById("jitsi-container");
      if (!container) {
        console.error("Jitsi container not found!");
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Video container is missing or not properly initialized."
        });
        return;
      }

      const options = {
        roomName: `vpaas-magic-cookie-67c319b07a0d4f1bb6954f8689924aaa/room-${room.doctorId}`,
        jwt: token,
        width: "100%",
        height: 600,
        parentNode: container,
        userInfo: {
          displayName: "Veterinarian"
        },
        configOverwrite: {
          prejoinPageEnabled: false
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: ["microphone", "camera", "hangup", "chat", "settings"],
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
        }
      };

      try {
        this.api = new (window as any).JitsiMeetExternalAPI("8x8.vc", options);

        this.api.addEventListener("videoConferenceJoined", () => {
          console.log("Video conference started.");
        });

        this.api.addEventListener("videoConferenceLeft", () => {
          console.log("Video conference ended.");
          this.leaveConversation();
        });
      } catch (error) {
        console.error("Failed to initialize Jitsi Meet:", error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to initialize the video call."
        });
      }
    }, 0);
  }

  disposeVideoCall(): void {
    if (this.api) {
      this.api.dispose();
      this.api = null;
      console.log("Video call disposed.");
    }
  }

  saveNotes(): void {
    if (this.currentConversation) {
      const log: ConversationLogDto = {
        id: 0,
        doctorId: this.currentConversation.doctorId,
        userId: this.currentConversation.userId,
        startTimeUtc: new Date().toISOString(),
        endTimeUtc: new Date().toISOString(),
        notes: this.conversationNotes
      };

      this.conversationService.logConversation(log).subscribe(() => {
        console.log("Notes saved successfully.");
        this.conversationNotes = "";
        this.currentConversation = null;
        this.isAvailable = false;
        this.loadStatusAndRooms();
      });
    }
  }

  leaveConversation(): void {
    if (!this.currentConversation) {
      this.messageService.add({
        severity: "warn",
        summary: "No Active Conversation",
        detail: "You are not currently in a conversation."
      });
      return;
    }

    const updatedRoom: ConversationRoomDto = {
      ...this.currentConversation,
      userId: null,
      dateLastUpdatedUtc: new Date().toISOString()
    };

    this.conversationService.updateRoom(updatedRoom).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Conversation Ended",
        detail: "You have successfully ended the conversation."
      });
      this.disposeVideoCall(); // Dispose video call when leaving the conversation
      this.currentConversation = null;
      this.isAvailable = false;
      this.loadStatusAndRooms();
    });
  }
}
