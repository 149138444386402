<app-prospect-trialperiod-scheduler (prospectIdSelected)="onNewProspectIdSelected($event)"></app-prospect-trialperiod-scheduler>

<p-dialog
  *ngIf="farmDialogVisible"
  [header]="'Rediger farm' | translate"
  [(visible)]="farmDialogVisible"
  [modal]="true"
  [style]="{ width: '95vw', minHeight: '90vh' }"
  [maximizable]="true"
  (onHide)="farmDialogVisible = false"
>
  <app-prospect-detail [prospectId]="selectedFarmId" (closeDialog)="handleCloseDialog($event)"></app-prospect-detail>
</p-dialog>
