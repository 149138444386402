import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoanableMaterialDto } from "@apiModels/loanableMaterialDto";
import { LoanTransactionDto } from "@apiModels/loanTransactionDto";
import { LoanableMaterialDtoExt } from "@globals/ExtModels/LoanableMaterialDtoExt";
import { CustomerService } from "@globals/services/customer.service"; // Import your service
import { InventoryService } from "@globals/services/inventory.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { storeItems, userItems } from "@shared/interfaces-and-enums/shared-data";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, catchError, finalize, map, of } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./loanable-material-list.component.html",
  styleUrls: ["./loanable-material-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanableMaterialListComponent implements OnInit {
  @ViewChild("table") table: Table;
  public loanableMaterialForm: FormGroup;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  public loading = true;
  public itemList$: Observable<Array<LoanableMaterialDtoExt>>;
  public availabilityOptions = [
    { label: "Alle", value: null },
    { label: "Tilgængelige", value: true },
    { label: "Ikke tilgængelige", value: false }
  ];

  private transactionTypeMap: { [key: number]: string } = {
    1: "Redigering",
    2: "Udlån Kunde",
    3: "Udlån Ansat",
    4: "Aflevering",
    5: "Flytning",
    6: "Anden Type"
  };

  public getTransactionTypeText(id: number | undefined): string {
    if (!id) {
      return "Ukendt";
    }
    return this.transactionTypeMap[id] || "Ukendt";
  }

  public selectedItem: LoanableMaterialDtoExt | null = null;
  editDialogVisible = false;
  customerLoanDialogVisible = false;
  employeeLoanDialogVisible = false;
  returnDialogVisible = false;
  moveDialogVisible = false;

  public transactionDialogVisible = false;
  public transactionList: LoanTransactionDto[] = [];

  public userItems = userItems;
  public storeItems = storeItems;

  public customerItems: SelectItem[] = [];
  public selectedLocationUserItem: SelectItem | null = null;
  public selectedLocationCustomerItem: SelectItem | null = null;
  public comments: string | null = null;

  public columns: Array<TableColumnPrimeNgExt> = [
    { field: "name", header: "Navn" },
    { field: "description", header: "Beskrivelse" },
    { field: "location", header: "Placering" },
    { field: "serialNumber", header: "Serienummer" },
    { field: "available", header: "Tilgængelig" },
    { field: "active", header: "Aktiv" }
  ];

  public globalFilterFields: Array<string> = ["name", "description", "location", "serialNumber"];
  public isEditing = false;

  private customersLoaded = false;

  constructor(
    public inventoryService: InventoryService,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.initializeItems();
    this.loanableMaterialForm = this.fb.group({
      id: 0,
      name: ["", Validators.required],
      description: "",
      comments: "",
      serialNumber: "",
      locationUserId: this.userId,
      locationCustomerId: null,
      isAvailable: true,
      isActive: true
    });
  }

  public initializeItems() {
    this.loading = true;

    this.itemList$ = this.inventoryService.getLoanableMaterials().pipe(
      map((materials: LoanableMaterialDto[]) =>
        materials.map(material => {
          const extendedMaterial: LoanableMaterialDtoExt = {
            ...material,
            location: material.locationUserId ? material.userName : material.locationCustomerId ? material.customerName : "Ikke angivet",
            available: material.isAvailable ? "Ja" : "Nej - udlånt",
            active: material.isActive ? "Ja" : "Nej - Kan ikke udlånes"
          };
          return extendedMaterial;
        })
      ),
      catchError(error => {
        console.error("Error loading loanable materials:", error);
        this.messageService.add({
          severity: "error",
          summary: "Fejl",
          detail: "Kunne ikke indlæse materialelisten.",
          life: 5000
        });
        return of([]);
      }),
      finalize(() => {
        this.loading = false;
        this.cd.markForCheck();
      })
    );
  }

  loadCustomers() {
    if (!this.customersLoaded) {
      this.loading = true;
      this.customerService.getCustomers(1).subscribe(customers => {
        this.customerItems = customers.map(c => ({
          label: c.name,
          value: c.id
        }));
        this.customersLoaded = true;
        this.loading = false;
      });
    }
  }

  editLoanableMaterial(selectedMaterial: LoanableMaterialDtoExt) {
    this.selectedItem = selectedMaterial;
    this.loanableMaterialForm.reset();
    this.loanableMaterialForm.patchValue(this.selectedItem);

    this.isEditing = true;
    this.editDialogVisible = true;
    this.cd.markForCheck();
  }

  addNewLoanableMaterial() {
    this.selectedItem = null;
    this.isEditing = false;
    this.loanableMaterialForm.reset();
    this.loanableMaterialForm.patchValue({ id: 0, isActive: true, isAvailable: true, locationUserId: this.userId });
    this.editDialogVisible = true;
    this.cd.markForCheck();
  }

  handleCloseDialog(savedMaterial: LoanableMaterialDtoExt | null) {
    this.editDialogVisible = false;
    this.loanableMaterialForm.reset();
    if (savedMaterial) {
      this.initializeItems();
    }
    this.cd.markForCheck();
  }

  saveLoanableMaterial() {
    const materialData: LoanableMaterialDtoExt = this.loanableMaterialForm.value;

    materialData.transactionTypeId = 1;

    this.inventoryService.createOrUpdateLoanableMaterial(materialData).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Succes",
        detail: "Materiale blev gemt.",
        life: 3000
      });
      this.editDialogVisible = false;
      this.handleCloseDialog(materialData);
    });
  }

  filterByAvailability(value: boolean | null) {
    this.table.filter(value, "isAvailable", "equals");
  }

  openLoanCustomerDialog(selectedMaterial: LoanableMaterialDtoExt) {
    this.loadCustomers();
    this.selectedItem = selectedMaterial;
    this.customerLoanDialogVisible = true;
  }

  openLoanEmployeeDialog(selectedMaterial: LoanableMaterialDtoExt) {
    this.selectedItem = selectedMaterial;
    this.employeeLoanDialogVisible = true;
  }

  openReturnDialog(selectedMaterial: LoanableMaterialDtoExt) {
    this.selectedItem = selectedMaterial;
    this.returnDialogVisible = true;
  }

  openMoveDialog(selectedMaterial: LoanableMaterialDtoExt) {
    this.selectedItem = selectedMaterial;
    this.moveDialogVisible = true;
  }

  saveCustomerLoan() {
    this.selectedItem.locationCustomerId = this.selectedLocationCustomerItem?.value;
    this.selectedItem.locationUserId = null;
    this.selectedItem.isAvailable = false;
    this.selectedItem.transactionTypeId = 2;
    this.selectedItem.comments = this.comments;

    this.inventoryService.createOrUpdateLoanableMaterial(this.selectedItem).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Succes",
          detail: "Materiale blev gemt.",
          life: 3000
        });

        this.selectedLocationCustomerItem = null;
        this.initializeItems();

        this.customerLoanDialogVisible = false;

        this.cd.markForCheck();
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Fejl",
          detail: "Der opstod en fejl ved gemning af materiale.",
          life: 3000
        });
      }
    );
  }

  saveEmployeeLoan() {
    const materialData = this.selectedItem;
    materialData.locationUserId = this.selectedLocationUserItem?.value;
    materialData.locationCustomerId = null;
    materialData.isAvailable = false;
    this.selectedItem.transactionTypeId = 3;
    this.selectedItem.comments = this.comments;

    this.inventoryService.createOrUpdateLoanableMaterial(materialData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Succes",
          detail: "Materiale blev gemt.",
          life: 3000
        });

        this.selectedLocationUserItem = null;
        this.initializeItems();

        this.employeeLoanDialogVisible = false;

        this.cd.markForCheck();
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Fejl",
          detail: "Der opstod en fejl ved gemning af materiale.",
          life: 3000
        });
      }
    );
  }

  processReturn() {
    const materialData = this.selectedItem;
    materialData.locationUserId = this.selectedLocationUserItem?.value;
    materialData.locationCustomerId = null;
    materialData.isAvailable = true;
    this.selectedItem.transactionTypeId = 4;
    this.selectedItem.comments = this.comments;

    this.inventoryService.createOrUpdateLoanableMaterial(materialData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Succes",
          detail: "Materiale blev afleveret.",
          life: 3000
        });

        this.selectedLocationUserItem = null;
        this.initializeItems();

        this.returnDialogVisible = false;

        this.cd.markForCheck();
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Fejl",
          detail: "Der opstod en fejl ved aflevering af materiale.",
          life: 3000
        });
      }
    );
  }

  processMove() {
    const materialData = this.selectedItem;
    materialData.locationUserId = this.selectedLocationUserItem?.value;
    materialData.locationCustomerId = null;
    materialData.isAvailable = true;
    this.selectedItem.transactionTypeId = 5;
    this.selectedItem.comments = this.comments;

    this.inventoryService.createOrUpdateLoanableMaterial(materialData).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          summary: "Succes",
          detail: "Materiale placering blev ændret.",
          life: 3000
        });

        this.selectedLocationUserItem = null;
        this.initializeItems();

        this.moveDialogVisible = false;

        this.cd.markForCheck();
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: "Fejl",
          detail: "Der opstod en fejl ved ændring af placering.",
          life: 3000
        });
      }
    );
  }

  /**
   * Called when user clicks the "Historik" button.
   * 1. Sets the selectedItem.
   * 2. Calls the service to fetch transactions for the item.
   * 3. Shows the transaction history dialog.
   */
  openTransactionDialog(selectedMaterial: LoanableMaterialDtoExt) {
    this.selectedItem = selectedMaterial;
    // For safety, clear any old data
    this.transactionList = [];

    this.inventoryService.getLoanTransactionsByMaterialId(selectedMaterial.id).subscribe({
      next: (transactions: LoanTransactionDto[]) => {
        this.transactionList = transactions;
        this.transactionDialogVisible = true;
        this.cd.markForCheck();
      },
      error: err => {
        this.messageService.add({
          severity: "error",
          summary: "Fejl",
          detail: "Kunne ikke hente transaktionshistorikken."
        });
      }
    });
  }

  /**
   * Returns the number of whole days between the start and end date.
   * If either date is null or invalid, returns 0 (or handle as you prefer).
   */
  public computeDaysOut(start: Date | string | null, end: Date | string | null): number {
    if (!start || !end) {
      return 0;
    }
    const startDate = new Date(start);
    const endDate = new Date(end);

    // If they're invalid dates, you could return 0 or handle differently
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return 0;
    }

    const msDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = msDiff / (1000 * 60 * 60 * 24);

    // Return a rounded or integer version (depending on your needs)
    return Math.round(daysDiff);
  }

  /** Close dialog if user clicks "Luk" or the 'X' icon */
  closeTransactionDialog() {
    this.transactionDialogVisible = false;
    this.selectedItem = null;
    this.transactionList = [];
  }

  public exportToExcel() {
    const exportableColumns = this.columns.map(col => ({
      field: col.field,
      header: col.header
    }));
    PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
  }
}
