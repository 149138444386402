<div class="dashboard">
  <h2>Veterinarian Dashboard</h2>

  <!-- Availability Toggle -->
  <div class="availability">
    <button (click)="toggleAvailability()" [class.available]="isAvailable">
      {{ isAvailable ? "Set Unavailable" : "Set Available" }}
    </button>
  </div>

  <!-- Video Call -->
  <div id="jitsi-container" *ngIf="isAvailable" style="width: 100%; height: 600px; border: 1px solid #ccc;">
    <p *ngIf="!currentConversation">Waiting for a landmand to join...</p>
  </div>

  <!-- Active Conversations -->
  <div class="active-rooms" *ngIf="currentConversation">
    <h3>Active Conversation</h3>
    <p>Currently connected to a landmand.</p>
  </div>

  <!-- Conversation Notes -->
  <div class="conversation-notes" *ngIf="currentConversation">
    <h3>Conversation with Landmand</h3>
    <textarea [(ngModel)]="conversationNotes" placeholder="Enter notes for this conversation..."></textarea>
    <button (click)="saveNotes()">Save Notes</button>
  </div>
</div>
