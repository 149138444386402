<div class="grid">
  <div class="col-12">
    <div class="filters-container">
      <!-- Materiale Filter -->
      <div class="filter-item">
        <label for="materialFilter">Materiale</label>
        <p-dropdown [options]="materialFilterItems" [(ngModel)]="selectedMaterialFilterItem" optionLabel="label" placeholder="Vælg materiale" (onChange)="applyFilters()"> </p-dropdown>
      </div>

      <!-- Fra Lager Filter -->
      <div class="filter-item">
        <label for="fromUserFilter">Fra Lager</label>
        <p-dropdown [options]="userFilterSelectItems" [(ngModel)]="selectedUserFilterItem" optionLabel="label" placeholder="Vælg fra" (onChange)="applyFilters()"> </p-dropdown>
      </div>
    </div>
    <div class="card">
      <p-table
        #table
        [value]="filteredInventoryData"
        [columns]="columns"
        [globalFilterFields]="globalFilterFields"
        [loading]="loading"
        selectionMode="single"
        dataKey="id"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[25, 50, 100, 500]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Forbrugsvarer lager</h5>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <button pButton pRipple label="Opdater lager" icon="pi pi-plus" class="p-button-success" (click)="openDialogWithPreset(null, 'purchase')"></button>

            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
            <th>Registrer Indkøb/Forbrug</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              <ng-container>
                {{ rowData[col.field] }}
              </ng-container>
            </td>
            <!-- Ekstra kolonne med knapper -->
            <td>
              <button pButton icon="pi pi-plus" label="Indkøb" class="p-button-success" (click)="openDialogWithPreset(rowData, 'purchase')"></button>
              <button pButton icon="pi pi-minus" label="Forbrug" class="p-button-danger ml-2" (click)="openDialogWithPreset(rowData, 'consumption')"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog [(visible)]="dialogVisible" modal="true" header="Opdater Lager" [style]="{ width: '40rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" (onShow)="focusQuantityInput()">
  <form (ngSubmit)="saveInventoryUpdate()" #inventoryForm="ngForm">
    <div class="p-fluid">
      <div class="field">
        <label for="material">Materiale</label>
        <p-dropdown [options]="materials" [(ngModel)]="selectedMaterial" optionLabel="name" dataKey="id" placeholder="Vælg materiale" name="material" [required]="true"></p-dropdown>
      </div>

      <div class="field">
        <label for="user">Lager placering</label>
        <p-dropdown [options]="userSelectItems" [(ngModel)]="selectedUserItem" optionLabel="label" dataKey="value" placeholder="Vælg lager placering" name="user" [required]="true"></p-dropdown>
      </div>

      <div class="field">
        <label for="operation">Operation</label>
        <p-dropdown [options]="operationOptions" [(ngModel)]="selectedOperation" placeholder="Vælg operation" name="operation" [required]="true"></p-dropdown>
      </div>

      <div class="field">
        <label for="comments">Kommentar</label>
        <textarea [(ngModel)]="selectedComment" name="comments" placeholder="Tilføj kommentar (valgfrit)" class="p-inputtextarea p-component" rows="1"></textarea>
      </div>

      <div class="field">
        <label for="quantity">Mængde</label>
        <input #quantityInput type="number" [(ngModel)]="selectedQuantity" name="quantity" placeholder="Angiv mængde" required class="p-inputtext p-component" />
      </div>
    </div>

    <button type="button" pButton label="Gem" [disabled]="!inventoryForm.valid" (click)="saveInventoryUpdate()"></button>
    <button type="button" pButton label="Annuller" class="p-button-secondary ml-2" (click)="closeDialog()"></button>
  </form>
</p-dialog>
