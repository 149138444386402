<div class="farmer-dashboard">
  <h2>Veterinarian Video Call</h2>

  <div *ngIf="joinStatus === 'searching'">
    <p>Searching for available veterinarians...</p>
  </div>

  <div *ngIf="availableRooms.length > 0 && joinStatus === 'idle'">
    <p>{{ availableRooms.length }} veterinarian(s) available.</p>
    <button pButton label="Join Conversation" (click)="joinRoom()" class="btn-primary"></button>
  </div>

  <div *ngIf="joinStatus === 'joined'">
    <p>You have joined a conversation. Please check your video call interface below:</p>
    <!-- Video Call Container -->
    <div id="jitsi-container" style="width: 100%; height: 600px; border: 1px solid #ccc;"></div>
  </div>

  <div *ngIf="availableRooms.length === 0 && joinStatus === 'idle'">
    <p>No veterinarians are currently available. Please try again later.</p>
    <button pButton label="Refresh" (click)="searchAvailableRooms()" class="btn-secondary"></button>
  </div>
</div>
