<p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
  <p-tabPanel header="Data">
    <app-prospect-detail-master-data [prospectId]="prospectId" (closeDialog)="handleCloseDialog($event)"></app-prospect-detail-master-data>
  </p-tabPanel>

  <p-tabPanel header="Noter">
    <app-prospect-detail-notes [prospectId]="prospectId"></app-prospect-detail-notes>
  </p-tabPanel>

  <p-tabPanel header="Opgaver">
    <app-prospect-detail-activities [prospectId]="prospectId"></app-prospect-detail-activities>
  </p-tabPanel>

  <p-tabPanel header="Landmandens overslag">
    <app-prospect-detail-benefit [prospectId]="prospectId"></app-prospect-detail-benefit>
  </p-tabPanel>

  <p-tabPanel header="Emails">
    <app-prospect-detail-emaillist [RefTypeId]="10" [RefId]="prospectId" [loadOnInit]="false"></app-prospect-detail-emaillist>
  </p-tabPanel>
</p-tabView>
