import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProspectDto } from "@apiModels/hubspotCompanyDto";
import { ProspectModel } from "@apiModels/prospectModel";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { farmTypeItems, mejeriItems, statusFilterItems, userItemsWithNotSelected } from "@shared/interfaces-and-enums/shared-data";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { finalize, take, tap } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./prospect-list.component.html",
  styleUrls: ["./prospect-list.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = false;

  public prospects: Array<ProspectModel> = [];
  filteredProspects: ProspectModel[] = [];

  selectedValue: string;

  selectedProspect: ProspectModel;

  public fraKoer = 94;
  public tilKoer = 5000;

  public userFilterItems = userItemsWithNotSelected;

  public userItems = userItemsWithNotSelected;

  public statusFilterItems = statusFilterItems;

  public mejeriItems = mejeriItems;

  public farmTypeItems = farmTypeItems;

  public farmUserItem: SelectItem;

  public farmStatusItem: SelectItem;

  public selectedUserItems: SelectItem[] = [];

  public selectedStatusItems: SelectItem[] = [];

  public selectedMejeriItems: SelectItem[] = [];

  public selectedFarmTypeItems: SelectItem[] = [];

  cols: any[];

  public selectedFarmId: number;
  public farmDialogVisible = false;

  constructor(
    public hubspotService: HubspotService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cols = [
      { field: "id", header: "Id" },
      { field: "chrNr", header: "ChrNr" },
      { field: "besNr", header: "BesNr" },
      { field: "ejerNavn", header: "EjerNavn" },
      { field: "cvrNummer", header: "CvrNummer" },
      { field: "adresse", header: "Adresse" },
      { field: "postNummer", header: "PostNr" },
      { field: "postDistrikt", header: "Post Dist" },
      { field: "byNavn", header: "ByNavn" },
      { field: "besaetningstype", header: "Besætningstype" },
      { field: "koer", header: "Køer" },
      { field: "malkesystem", header: "Malkesystem" },
      { field: "kvier", header: "Kvier" },
      { field: "handyr", header: "Handyr" },
      { field: "region", header: "Region" }
    ];

    this.resetFilter(true);
  }

  private applyClientSideFilters() {
    this.filteredProspects = this.prospects.filter(farm => {
      const userFilterPassed = this.selectedUserItems.map(item => item.value).includes(farm.ansvarligBrugerId);

      const statusItemsFilterPassed = this.selectedStatusItems.map(item => item.value).includes(farm.status);

      const mejeriFilterPassed = this.selectedMejeriItems.map(item => item.value).includes(farm.mejerier);

      const farmTypeFilterPassed = this.selectedFarmTypeItems.map(item => item.value).includes(farm.besaetningstype);

      const koerFilterPassed = farm.koer >= this.fraKoer && farm.koer <= this.tilKoer;

      return userFilterPassed && statusItemsFilterPassed && mejeriFilterPassed && farmTypeFilterPassed && koerFilterPassed;
    });
  }

  public removeFilter(refresh: boolean) {
    this.fraKoer = 0;
    this.tilKoer = 9999;

    this.selectedStatusItems = this.statusFilterItems;
    this.selectedUserItems = this.userFilterItems;
    this.selectedMejeriItems = this.mejeriItems;
    this.selectedFarmTypeItems = this.farmTypeItems;

    if (refresh) this.handleFilterChange();
  }

  public resetFilter(refresh: boolean) {
    this.fraKoer = 94;
    this.tilKoer = 9999;

    this.selectedStatusItems = this.statusFilterItems.filter(item => item.value !== "Kunde" && item.value !== "Afvist" && item.value !== "OphoertIChr");
    this.selectedUserItems = this.userFilterItems;
    this.selectedMejeriItems = this.mejeriItems;
    this.selectedFarmTypeItems = this.farmTypeItems;

    if (refresh) this.handleFilterChange();
  }

  public handleFilterChange() {
    this.initializeProspects();
  }

  public openFarmDialog(farm: ProspectModel) {
    this.selectedFarmId = farm.id;
    this.farmDialogVisible = true;
    this.cd.markForCheck();
  }

  private initializeProspects() {
    this.loading = true;
    if (this.prospects.length === 0) {
      this.hubspotService
        .getProspects()
        .pipe(
          take(1),
          tap((data: ProspectModel[]) => {
            this.prospects = data;
            this.applyClientSideFilters();
          }),
          finalize(() => {
            this.loading = false;
            this.cd.markForCheck();
          }),
          untilDestroyed(this)
        )
        .subscribe();
    } else {
      this.applyClientSideFilters();
      this.loading = false;
      this.cd.markForCheck();
    }
  }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  showOnMap(item: ProspectModel) {
    this.router.navigate(["/ansvarkort"], { queryParams: { id: item.id } });
  }

  handleCloseDialog(updatedCompany: ProspectDto | null) {
    this.farmDialogVisible = false;
    this.selectedFarmId = null;
    this.cd.markForCheck();
    //this.selectedWeek = null;
  }
}
