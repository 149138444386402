import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConfirmationService, MessageService, SortEvent } from "primeng/api";
import { finalize, map, Observable, ReplaySubject, take, tap } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "@globals/services/customer.service";
import { BiLocalizationHelperService } from "@core/utility-services/bi-localization-helper.service";
import moment from "moment";
import { TableColumnPrimeNg } from "@shared/interfaces-and-enums/TableColumnPrimeNg";
import { EmailModel } from "@apiModels/emailModel";
import { EmailAttachmentModel } from "@apiModels/emailAttachmentModel";
import { BiDomService } from "@core/utility-services/bi-dom.service";

export interface EmailModelExt extends EmailModel {
  dateSentForSort?: moment.Moment;
  dateSent?: string;
}

export interface TableColumnPrimeNgExt extends TableColumnPrimeNg {
  sortField?: string;
}

@UntilDestroy()
@Component({
  selector: "app-prospect-detail-emaillist",
  templateUrl: "prospect-detail-emaillist.component.html",
  styleUrls: ["./prospect-detail-emaillist.component.scss"],
  providers: [MessageService, ConfirmationService]
})
export class ProspectDetailEmailListComponent implements OnInit {
  @Input() RefId: number;
  @Input() RefTypeId: number;
  @Input() loadOnInit: boolean = false; // Standard er ikke at loade ved init

  public loading = true;
  public emails$: Observable<Array<EmailModel>>;
  public emails: Array<EmailModel> = [];
  private columns = new ReplaySubject<Array<TableColumnPrimeNgExt>>(1);
  public columns$ = this.columns.asObservable();

  private globalFilterFields = new ReplaySubject<Array<string>>(1);
  public globalFilterFields$ = this.globalFilterFields.asObservable();
  public src: Blob;
  public pdfViewerVisible = false;
  public pdfSpinner = false;
  public displayPdfDialog: boolean;

  selectedValue: string;

  selectedEmail: EmailModel;

  cols: any[];

  showDeleted: boolean;

  displayEmailDialog: boolean;

  text: string;

  attachments: EmailAttachmentModel[] = [];
  displayEmailAttachmentsDialog: boolean;

  showEmail() {
    this.displayEmailDialog = true;
  }

  constructor(
    private customerService: CustomerService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private localizor: BiLocalizationHelperService,
    private cd: ChangeDetectorRef,
    private domService: BiDomService
  ) {}

  ngOnInit() {
    if (this.loadOnInit) {
      this.refreshEmails(); // Indlæs e-mails med det samme, hvis loadOnInit er true
    }
    this.initColumns();
  }

  onRowSelect(event) {
    // this.text = this.selectedEmail.body.substring(this.selectedEmail.body.lastIndexOf("<!--contentstart -->") + 20, this.selectedEmail.body.lastIndexOf("<!--contentend -->"));
    this.text = this.selectedEmail.body;
    this.showEmail();
  }

  showAttachments(email: EmailModel) {
    this.selectedEmail = email;

    this.customerService.getEmailAttachments(this.selectedEmail.id).subscribe(
      (data: Array<EmailAttachmentModel>) => {
        this.attachments = data;
      },
      error => {
        console.error("Failed to retrieve attachments:", error);
        this.attachments = [];
      },
      () => {
        this.loading = false;
        this.displayEmailAttachmentsDialog = true;
      }
    );
  }

  showAttachment(id: number, filename: string) {
    if (filename.toLowerCase().endsWith(".pdf")) {
      this.pdfSpinner = true;
      this.customerService.getEmailAttachmentById(id).subscribe(res => {
        this.src = res;
        this.pdfSpinner = false;
        this.showPdfDialog();
      });
    } else {
      this.customerService
        .downloadEmailAttachmentById(id)
        .pipe(take(1))
        .subscribe(data => {
          this.domService.downloadFile(data, filename, "application/msword");
        });
    }
  }

  showPdfDialog() {
    this.displayPdfDialog = true;
    setTimeout(() => {
      this.pdfViewerVisible = true;
      this.cd.detectChanges();
    }, 300);
  }

  public onCreateNewEmail() {
    this.router.navigate([0, "main"], { relativeTo: this.activeRoute });
  }

  private initColumns() {
    this.globalFilterFields.next(["subject", "body", "toName", "toEmail"]);
    this.columns.next([
      { field: "dateSent", header: "Sendt", sortField: "dateSentForSort" },
      { field: "subject", header: "Emne" },
      { field: "toName", header: "Til navn" },
      { field: "toEmail", header: "Til" },
      { field: "fromName", header: "Fra navn" },
      { field: "fromEmail", header: "Fra" }
    ]);
  }

  public refreshEmails() {
    if (this.RefId && this.RefTypeId) {
      this.loading = true;
      this.emails$ = this.customerService.emailsByRefId(this.RefId, this.RefTypeId).pipe(
        tap((data: Array<EmailModelExt>) => {
          data.forEach(element => {
            element.dateSent = this.localizor.localizeDateTime(element.dateSentUtc);
            element.dateSentForSort = moment(element.dateSentUtc);
          });
        }),
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
        })
      );
    } else {
      console.error("RefId or RefTypeId is not provided.");
    }
  }

  customSort(event: SortEvent) {
    this.columns$.pipe(map(columns => columns.find(f => f.field === event.field))).subscribe(col => {
      let value1: any, value2: any;
      event.data.sort((data1, data2) => {
        if (col && col.sortField) {
          value1 = +data1[col.sortField];
          value2 = +data2[col.sortField];
        } else {
          value1 = data1[event.field];
          value2 = data2[event.field];
        }

        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === "string" && typeof value2 === "string") {
          result = value1.localeCompare(value2);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }
        return event.order * result;
      });
    });
  }
}
