import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConversationLogDto } from "@apiModels/conversationLogDto";
import { ConversationRoomDto } from "@apiModels/conversationRoomDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ConversationService {
  constructor(private http: HttpClient) { }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  public generateJaaSToken(roomName: string, userName: string, isModerator: boolean): Observable<string> {
    const params = {
      roomName,
      userName,
      isModerator: isModerator.toString()
    };

    return this.http
      .get<string>(ApiRoutes.conversationRoutes.get.generateJaaSToken, {
        params,
        responseType: "text" as "json"
      })
      .pipe(catchError(this.handleError("generateJaaSToken", "")));
  }

  public updateRoom(dto: ConversationRoomDto): Observable<ConversationRoomDto[]> {
    return this.http.post<ConversationRoomDto[]>(ApiRoutes.conversationRoutes.post.updateRoom, dto).pipe(catchError(this.handleError("updateRoom", [])));
  }

  public getAllRooms(): Observable<ConversationRoomDto[]> {
    return this.http.get<ConversationRoomDto[]>(ApiRoutes.conversationRoutes.get.getAllRooms).pipe(catchError(this.handleError("getAllRooms", [])));
  }

  public logConversation(dto: ConversationLogDto): Observable<ConversationLogDto> {
    return this.http.post<ConversationLogDto>(ApiRoutes.conversationRoutes.post.logConversation, dto).pipe(catchError(this.handleError("logConversation", null as any)));
  }
}
