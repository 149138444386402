<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-toast></p-toast>
      <p-table
        #table
        [value]="itemList$ | async"
        [columns]="columns$ | async"
        [globalFilterFields]="globalFilterFields$ | async"
        [loading]="loading"
        selectionMode="single"
        [(selection)]="selectedItem"
        dataKey="id"
        (onRowSelect)="onRowSelect($event)"
        csvSeparator=";"
        [exportFilename]="'export'"
        [paginator]="true"
        [rows]="25"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="Viser {first} til {last} af {totalRecords} poster"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Certificeringer</h5>

            <button pButton pRipple label="Opret manuel certificering" icon="pi pi-plus" class="p-button-success mr-2" (click)="onAddNewCertificationClick()"></button>

            <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToExcel()"></button>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [header]="'Rediger certificat'"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '90rem', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-4">
            <label for="languageSelect">Vælg sprog:</label>
            <p-dropdown
              [options]="languageOptions"
              [(ngModel)]="selectedLanguage"
              optionLabel="label"
              [ngModelOptions]="{ standalone: true }"
              selectedItemTemplate="selectedItem"
              (ngModelChange)="onLanguageChange($event)"
            >
              <ng-template let-option pTemplate="item">
                <div class="flag-option">
                  <img class="flag-icon mr-2" [src]="'https://flagcdn.com/32x24/' + getFlagIcon(option.value) + '.png'" [alt]="option.label" />
                  <span>{{ option.label }}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="selectedItem">
                <div class="flag-option">
                  <img class="flag-icon mr-2" [src]="'https://flagcdn.com/32x24/' + getFlagIcon(selectedLanguage.value) + '.png'" [alt]="selectedLanguage.label" />
                  <span>{{ selectedLanguage.label }}</span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form *ngIf="mainForm" [formGroup]="mainForm">
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-4">
              <label for="chNumber">{{ currentLabels.chrNrLabel }}:</label>
              <input formControlName="chrNr" type="number" pInputText minlength="4" maxlength="6" autocomplete="one-time-code" id="qwer" />
              <div *ngIf="chrNr.invalid && (chrNr.dirty || chrNr.touched)">
                <div *ngIf="chrNr.errors && (chrNr.dirty || chrNr.touched)" class="p-error block">
                  <div *ngIf="chrNr.errors?.required">{{ currentLabels.isRequiredLabel }}</div>
                  <div *ngIf="chrNr.errors?.min || chrNr.errors?.max">{{ currentLabels.fourToSixDigitsLabel }}</div>
                </div>
              </div>
            </div>

            <div class="field col-12 md:col-4">
              <label for="name">{{ currentLabels.firstAndLastnameLabel }}:</label>
              <input formControlName="name" type="text" pInputText autocomplete="one-time-code" id="tyui" />
              <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors && (name.dirty || name.touched)" class="p-error block">
                  <div *ngIf="name.errors?.required">{{ currentLabels.isRequiredLabel }}</div>
                  <div *ngIf="name.errors?.minlength">Minimum 3 bokstaver</div>
                  <!-- <div *ngIf="name.errors?.fullName">{{ currentLabels.firstAndLastnameLabel }}</div> -->
                </div>
              </div>
            </div>

            <div class="field col-12 md:col-4">
              <label for="birthday">{{ currentLabels.birthdayLabel }}:</label>
              <input formControlName="birthday" type="text" pInputText autocomplete="one-time-code" id="opas" />
              <div *ngIf="birthday.invalid && (birthday.dirty || birthday.touched)">
                <div *ngIf="birthday.errors && (birthday.dirty || birthday.touched)" class="p-error block">
                  <div *ngIf="birthday.errors?.required">{{ currentLabels.isRequiredLabel }}</div>
                  <div *ngIf="birthday.errors?.invalidBirthdayFormat">{{ currentLabels.wrongFormatLabel }}</div>
                </div>
              </div>
            </div>

            <div class="field col-12 md:col-4">
              <label for="email">{{ currentLabels.emailLabel }}:</label>
              <input formControlName="email" type="text" pInputText autocomplete="one-time-code" id="dfgh" />
              <div *ngIf="email.invalid && (email.dirty || email.touched)">
                <div *ngIf="email.errors && (email.dirty || email.touched)" class="p-error block">
                  <div *ngIf="email.errors?.email && email.touched">Invalid email</div>
                  <div *ngIf="email.errors?.required">{{ currentLabels.isRequiredLabel }}</div>
                </div>
              </div>
            </div>

            <div class="field col-12 md:col-4">
              <label for="phone">{{ currentLabels.phoneLabel }}:</label>
              <input formControlName="phone" type="number" pInputText autocomplete="one-time-code" id="jklz" />
              <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="p-error block">
                  <div *ngIf="phone.errors?.required">{{ currentLabels.isRequiredLabel }}</div>
                  <div *ngIf="phone.errors?.min">{{ currentLabels.min8digitsLabel }}</div>
                </div>
              </div>
            </div>
            <div class="field col-12">
              <div class="formgroup-inline">
                <div class="field">
                  <button
                    pButton
                    pRipple
                    type="button"
                    [label]="selectedItem.id === 0 ? 'Opret' : 'Gem'"
                    class="p-button-raised"
                    [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                    [disabled]="mainForm.pristine || !mainForm.valid"
                    (click)="saveItem()"
                  ></button>
                </div>

                <div class="field">
                  <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Luk" title="Luk" (click)="dialogVisible = false"></button>
                </div>

                <div class="field">
                  <button
                    pButton
                    pRipple
                    type="button"
                    [label]="'Gensend certifikat'"
                    class="p-button-raised"
                    [title]="mainForm.valid ? 'Gensend certifikat' : 'Deaktiveret, indtil formulardataene er gemt'"
                    [disabled]="!mainForm.pristine || selectedItem.id === 0"
                    (click)="gensend()"
                  ></button>
                </div>
              </div>

              <!-- <div *ngIf="userId === 25" class="field col-12">
              <div class="field">
                <button pButton pRipple class="p-button-outlined p-button-secondary" type="button" label="Test form for validation in console" (click)="getFormValidationErrors()"></button>
              </div>
              <br />Dirty: {{ mainForm.dirty }} <br />Touched: {{ mainForm.touched }}<br />Pristine: {{ mainForm.pristine }} <br />Valid: {{ mainForm.valid }} <br />Values:
              {{ mainForm.value | json }}
              <p class="field-error" [@fadeIn] *ngIf="showFormErrorMessage" translate>errorMessages.OneOrMoreFieldsMissing</p>
            </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog [header]="currentLabels.dialogErrorHeaderLabel" [(visible)]="displayErrorDialog" modal="true" showCloseIcon="true" [responsive]="true" [closable]="true" [style]="{ width: '500px' }">
  <div [innerHTML]="currentLabels.dialogErrorBodyLabel"></div>
</p-dialog>

<p-dialog [header]="currentLabels.dialogChrErrorHeaderLabel" [(visible)]="displayChrErrorDialog" modal="true" showCloseIcon="true" [responsive]="true" [closable]="true" [style]="{ width: '500px' }">
  <div [innerHTML]="currentLabels.dialogChrErrorBodyLabel"></div>
</p-dialog>

<bi-spinner spinnerSize="large" [hasBackground]="true" [centerInParent]="true" *ngIf="loading"></bi-spinner>
