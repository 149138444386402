import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FarmAppDto } from "@apiModels/farmAppDto";
import { FarmDto } from "@apiModels/farmDto";
import { CustomerService } from "@globals/services/customer.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, finalize } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-farm-list",
  templateUrl: "./superadmin-customer-farmlist.component.html",
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperAdminFarmListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;
  private customerId: number;
  public farms: Array<FarmAppDto> = [];
  public farms$: Observable<Array<FarmAppDto>>;

  selectedItem: FarmDto;

  cols: any[];

  showDeleted: boolean;
  editDialogVisible: any;

  constructor(
    private customerService: CustomerService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.showDeleted = false;

    this.initializeItems();

    this.cols = [
      { field: "name", header: "Navn" },
      { field: "address", header: "Adresse" },
      { field: "phone", header: "Telefon" },
      { field: "email", header: "Email" },
      { field: "info", header: "Info" },
      { field: "chrNr", header: "Chr Nr" },
      { field: "besNr", header: "Besætnings Nr" },
      { field: "sidstCkrSyncUtc", header: "SidstCkrSync" }
    ];
  }

  onRowSelect(event) {
    this.selectedItem = event.data;
    this.editDialogVisible = true;
  }

  openFarmDialog(type: string): void {
    this.selectedItem = {
      id: 0,
      customerId: this.customerId,
      deleted: false
    };

    this.editDialogVisible = true;
  }
  public onShowActiveChange(checked: boolean) {
    if (checked) {
      this.showDeleted = true;
      this.initializeItems();
    } else {
      this.showDeleted = false;
      this.initializeItems();
    }
  }

  public initializeItems() {
    this.customerId = this.activeRoute.parent.snapshot.params.id;
    this.farms$ = this.customerService.getFarms(this.customerId).pipe(
      untilDestroyed(this),
      finalize(() => (this.loading = false))
    );
  }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  closeDialog() {
    this.selectedItem = null;
    this.editDialogVisible = false;
    this.cd.detectChanges();
  }
}
