import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoanableMaterialDto } from "@apiModels/loanableMaterialDto"; // Antag at du har denne DTO
import { LoanTransactionDto } from "@apiModels/loanTransactionDto";
import { MaterialDto } from "@apiModels/materialDto";
import { MaterialStockDto } from "@apiModels/materialStockDto";
import { MaterialTransactionDto } from "@apiModels/materialTransactionDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { catchError, Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class InventoryService {
  constructor(private http: HttpClient) {}

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private buildParams(params: { [key: string]: any }): { [key: string]: string } {
    const stringifiedParams: { [key: string]: string } = {};
    Object.keys(params).forEach(key => {
      stringifiedParams[key] = params[key].toString();
    });
    return stringifiedParams;
  }

  // Get all materials
  public getMaterials(): Observable<MaterialDto[]> {
    return this.http.get<MaterialDto[]>(ApiRoutes.inventoryRoutes.get.getMaterials);
  }

  // Create or update material
  public createOrUpdateMaterial(dto: MaterialDto): Observable<MaterialDto> {
    return this.http.post<MaterialDto>(ApiRoutes.inventoryRoutes.post.createOrUpdateMaterial, dto).pipe(catchError(this.handleError("createOrUpdateMaterial", {} as MaterialDto)));
  }

  // Get user's stock for a specific material
  public getUserStock(userId: number, materialId: number): Observable<MaterialStockDto> {
    const params = this.buildParams({ userId, materialId });
    return this.http.get<MaterialStockDto>(ApiRoutes.inventoryRoutes.get.getUserStock, { params }).pipe(catchError(this.handleError("getUserStock", {} as MaterialStockDto)));
  }

  // Get material stock for a specific user
  public getMaterialStock(userId: number): Observable<MaterialStockDto[]> {
    const params = this.buildParams({ userId });
    return this.http.get<MaterialStockDto[]>(ApiRoutes.inventoryRoutes.get.getMaterialStock, { params }).pipe(catchError(this.handleError("getMaterialStock", [] as MaterialStockDto[])));
  }

  // Create or update material stock
  public createOrUpdateMaterialStock(dto: MaterialStockDto): Observable<MaterialStockDto> {
    return this.http.post<MaterialStockDto>(ApiRoutes.inventoryRoutes.post.createOrUpdateMaterialStock, dto).pipe(catchError(this.handleError("createOrUpdateMaterialStock", {} as MaterialStockDto)));
  }

  // Get all material transactions for a user
  public getMaterialTransactions(): Observable<MaterialTransactionDto[]> {
    return this.http
      .get<MaterialTransactionDto[]>(ApiRoutes.inventoryRoutes.get.getMaterialTransactions, {})
      .pipe(catchError(this.handleError("getMaterialTransactions", [] as MaterialTransactionDto[])));
  }

  public getLoanableMaterials(): Observable<LoanableMaterialDto[]> {
    return this.http.get<LoanableMaterialDto[]>(ApiRoutes.inventoryRoutes.get.getLoanableMaterials).pipe(catchError(this.handleError("getLoanableMaterials", [] as LoanableMaterialDto[])));
  }

  public createOrUpdateLoanableMaterial(dto: LoanableMaterialDto): Observable<LoanableMaterialDto> {
    return this.http
      .post<LoanableMaterialDto>(ApiRoutes.inventoryRoutes.post.createOrUpdateLoanableMaterial, dto)
      .pipe(catchError(this.handleError("createOrUpdateLoanableMaterial", {} as LoanableMaterialDto)));
  }

  public getLoanTransactionsByMaterialId(loanableMaterialId: number): Observable<LoanTransactionDto[]> {
    const params = this.buildParams({ loanableMaterialId });
    return this.http
      .get<LoanableMaterialDto[]>(ApiRoutes.inventoryRoutes.get.getLoanTransactionsByMaterialId, { params })
      .pipe(catchError(this.handleError("getLoanTransactionsByMaterialId", [] as LoanTransactionDto[])));
  }
}
