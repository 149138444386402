import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DayService, DragAndDropService, EventRenderedArgs, EventSettingsModel, MonthService, ScheduleComponent, WeekService, WorkWeekService } from "@syncfusion/ej2-angular-schedule";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import moment from "moment";

// Import the required CLDR JSON files for Danish culture
import { ProspectTrialPeriodDto } from "@apiModels/prospectTrialPeriodDto";
import { ActivityService } from "@globals/services/activity.service";
import { userItems } from "@shared/interfaces-and-enums/shared-data";
import * as caGregorian from "cldr-data/main/da/ca-gregorian.json";
import * as currencies from "cldr-data/main/da/currencies.json";
import * as numbers from "cldr-data/main/da/numbers.json";
import * as timeZoneNames from "cldr-data/main/da/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";
import { SelectItem } from "primeng/api";
import { UserInfo, userInfos } from "../../activities/shared-data";

export interface TrialPeriodDay {
  id: number;
  typeId: number; // 1 = Teori, 2 = Praksis, 3 = Kontakt og 4 = Opfølgning
  userIds: number[];
  notes: string;
  datePlannedStartUtc?: string;
  datePlannedEndUtc?: string;
  plannedStartDate: Date;
  plannedStartTime: string;
  plannedEndDate: Date;
  plannedEndTime: string;
  activityId?: number | null;
}

@Component({
  selector: "app-trial-period-calendar",
  templateUrl: "./trial-period-calendar.component.html",
  styleUrls: ["./trial-period-calendar.component.scss"],
  providers: [DayService, WeekService, WorkWeekService, MonthService, DragAndDropService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialPeriodCalendarComponent implements OnInit {
  @ViewChild("scheduleObj", { static: false }) scheduleObj: ScheduleComponent;
  @ViewChild("eventTemplate", { static: true }) eventTemplate: any;

  @Input() trialPeriodId?: number;
  @Input() trialPeriod?: ProspectTrialPeriodDto;
  @Output() closeDialog = new EventEmitter<boolean>();

  close(hasChanges: boolean) {
    this.closeDialog.emit(hasChanges);
  }

  public currentDate: Date = new Date();
  public eventSettings: EventSettingsModel;
  public showWeekend: boolean = true;
  public appointments: Array<any> = [];
  public minDate: Date;
  public maxDate: Date;
  public dialogVisible = false;
  public selectedItem: TrialPeriodDay = null;
  trialPeriodDays: TrialPeriodDay[] = [];
  timeIntervals: SelectItem[] = [];

  selectedUserItems: SelectItem[] = [];

  plannedStartTime: Date;
  plannedEndTime: Date;

  public selectedLessonType: number;

  constructor(
    private activityService: ActivityService,
    private cd: ChangeDetectorRef
  ) {}

  lessonTypes = [
    { id: 1, name: "Teori", color: "#8B0000" }, // Dark Red for theory
    { id: 2, name: "Praktisk", color: "#006400" }, // Dark Green for practical
    { id: 0, name: "Egen hånd", color: "#00008B" }, // Dark Blue for self-study
    { id: 3, name: "Eval og afslut", color: "#8B008B" }, // Dark Magenta for evaluation and conclusion
    { id: 4, name: "Opfølgning", color: "#4B0082" } // Indigo for follow-up
  ];

  lessonPlan = [
    { dayOffset: 0, typeId: 1, note: "Der er kun teori denne dag!" }, // Teori med test torsdag
    { dayOffset: 1, typeId: 2 }, // Praktisk undervisning mandag
    { dayOffset: 1, typeId: 2 }, // Praktisk undervisning tirsdag
    { dayOffset: 1, typeId: 0, note: "Vi vil være i telefonisk kontakt efterfølgende" }, // Egen hånd (opfølgning telefonisk) onsdag
    { dayOffset: 1, typeId: 2 }, // Praktisk undervisning torsdag
    { dayOffset: 1, typeId: 2, note: "Vi evaluerer ugen der er gået. " }, // Praktisk undervisning fredag
    { dayOffset: 1, typeId: 0 }, // Laser anvendes på egen hånd mandag
    { dayOffset: 1, typeId: 0 }, // Laser anvendes på egen hånd tirsdag
    { dayOffset: 1, typeId: 3, note: "Vi evaluerer hele forløbet og afslutter hos jer" }, // Evaluering og afslutning onsdag
    { dayOffset: 15, typeId: 4 } // Opfølgning
  ];

  public tooltipTemplate: string = `
  <div class="tooltip-wrap">
    <div class="content-area">
      <div class="name">Emne: \${Subject}</div>
      <div class="description">Emne: \${Description}</div>
    </div>
  </div>
`;

  public userInfos: { [key: string]: UserInfo } = userInfos;
  public userItems = userItems;

  ngOnInit(): void {
    // TO BE DELETED
    if (window.location.href.includes("test")) {
      this.trialPeriodId = 2983;
    }

    loadCldr(numberingSystems, timeZoneNames, weekData, numbers, caGregorian, currencies);

    setCulture("da");

    L10n.load({
      da: {
        schedule: {
          day: "Dag",
          week: "Uge",
          workWeek: "Arbejdsuge",
          month: "Måned",
          agenda: "Dagsorden",
          today: "I dag",
          noEvents: "Ingen begivenheder",
          allDay: "Hele dagen",
          start: "Start",
          end: "Slut",
          more: "mere",
          close: "Luk"
        }
      }
    });

    this.initializeTimeIntervals();
    this.initializeTrialPeriodDays();
  }

  initializeTimeIntervals(): void {
    for (let i = 0; i < 24 * 2; i++) {
      const hours = Math.floor(i / 2)
        .toString()
        .padStart(2, "0");
      const minutes = i % 2 === 0 ? "00" : "30";
      this.timeIntervals.push({ label: `${hours}:${minutes}`, value: `${hours}:${minutes}` });
    }
  }

  initializeTrialPeriodDays(): void {
    if (this.trialPeriodId && !this.trialPeriod) {
      this.activityService.getTrialPeriod(this.trialPeriodId).subscribe(
        response => {
          this.trialPeriod = response;
          this.initializeTrialPeriodDays();
        },
        error => {
          console.error("Error fetching trial period:", error);
        }
      );
      return;
    }

    if (this.trialPeriod && this.trialPeriod.days && this.trialPeriod.days.length > 0) {
      this.trialPeriodDays = this.trialPeriod.days.map(day => ({
        id: day.id,
        typeId: day.typeId,
        userIds: day.userIds ? day.userIds.split(",").map(id => parseInt(id)) : [],
        notes: day.notes,
        datePlannedStartUtc: day.datePlannedStartUtc,
        datePlannedEndUtc: day.datePlannedEndUtc,
        plannedStartDate: new Date(day.datePlannedStartUtc),
        plannedEndDate: new Date(day.datePlannedEndUtc),
        plannedStartTime: moment(day.datePlannedStartUtc).format("HH:mm"),
        plannedEndTime: moment(day.datePlannedEndUtc).format("HH:mm")
      }));
    } else {
      const startHour = this.trialPeriod.trialPeriodStartHour;
      const startDay = this.trialPeriod.trialPeriodStartDay;
      const weekYear = this.trialPeriod.trialPeriodStartWeekYearId;
      const year = Math.floor(weekYear / 100);
      const week = weekYear % 100;
      let currentDate = this.getDateOfISOWeek(week, year, startDay);

      let id = 0;

      this.lessonPlan.forEach((lesson, index) => {
        if (index === 1 && (startDay === 4 || startDay === 5)) {
          // Hvis det er den første praktikdag, og startdag er torsdag eller fredag, flyt til næste mandag
          currentDate.setDate(currentDate.getDate() + (7 - (currentDate.getDay() || 7)));
        } else {
          // Tilføj dayOffset for alle andre dage
          currentDate.setDate(currentDate.getDate() + lesson.dayOffset);
        }

        // Spring weekender over
        while (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
          currentDate.setDate(currentDate.getDate() + 1);
        }

        const startDate = new Date(currentDate);
        const endDate = new Date(currentDate);

        startDate.setHours(startHour, 0, 0);
        endDate.setHours(startHour + 2, 0, 0);

        id--; // Brug negative ID'er for nye dage

        this.trialPeriodDays.push({
          id: id,
          typeId: lesson.typeId,
          userIds: this.trialPeriod.trialPeriodUserIds ? this.trialPeriod.trialPeriodUserIds.split(",").map(id => parseInt(id)) : [],
          notes: lesson.note || "",
          datePlannedStartUtc: startDate.toISOString(),
          datePlannedEndUtc: endDate.toISOString(),
          plannedStartDate: startDate,
          plannedEndDate: endDate,
          plannedStartTime: moment(startDate.toISOString()).format("HH:mm"),
          plannedEndTime: moment(endDate.toISOString()).format("HH:mm")
        });
      });
    }
    //console.log("Trial Period Days:", this.trialPeriodDays);
    this.initializeItems(); // Opdater data til kalenderen.
  }

  onServer() {
    return this.trialPeriodDays.filter(x => x.id > 0).length > 0;
  }

  reset() {
    this.trialPeriod.days = [];
    this.trialPeriodDays = [];
    this.initializeTrialPeriodDays();
  }

  getDateOfISOWeek(week, year, weekday = 4) {
    const simple = new Date(year, 0, 1 + (week - 1) * 7); // Første dag i ugen
    const dayOfWeek = simple.getDay(); // Ugedagen (0 = Søndag, 1 = Mandag osv.)
    const ISOWeekStart = simple;

    if (dayOfWeek <= 4) {
      ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1); // Første dag i ugen (Mandag)
    } else {
      ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay()); // Første dag i næste uge
    }

    // Juster til ønsket dag i ugen (4 = Torsdag)
    ISOWeekStart.setDate(ISOWeekStart.getDate() + (weekday - 1));

    return ISOWeekStart;
  }

  public initializeItems(): void {
    if (this.trialPeriodDays.length > 0) {
      this.calculateMinMaxDates();

      //const middleDate = new Date((this.minDate.getTime() + this.maxDate.getTime()) / 2);
      this.currentDate = this.minDate;

      if (this.scheduleObj) {
        this.scheduleObj.selectedDate = this.currentDate;
        this.scheduleObj.refresh();
      }
    }

    this.appointments = this.trialPeriodDays.map(day => ({
      Id: day.id,
      Subject: this.getLessonTypeName(day.typeId),
      TypeId: day.typeId,
      StartTime: moment(day.datePlannedStartUtc).toDate(),
      EndTime: moment(day.datePlannedEndUtc).toDate(),
      Employees: this.buildAssignedUsersList(day),
      Description: day.notes,
      EndTimeDK: moment(day.datePlannedStartUtc).tz("Europe/Copenhagen").format("HH:mm") + "-" + moment(day.datePlannedEndUtc).tz("Europe/Copenhagen").format("HH:mm") // Dansk klokkeslæt
    }));

    this.eventSettings = {
      dataSource: this.appointments,
      enableTooltip: true,
      tooltipTemplate: this.tooltipTemplate,
      template: this.eventTemplate,
      allowAdding: false,
      allowDeleting: false
      //allowEditing: false // så fjernes også drag drop
    };

    this.cd.markForCheck();
  }

  buildAssignedUsersList(activity: TrialPeriodDay): string[] {
    if (!activity.userIds || activity.userIds.length === 0) {
      return [];
    }

    // Map userIds to their initials from userInfos
    return activity.userIds
      .map(userId => {
        const user = Object.values(userInfos).find(user => user.id === userId);
        return user ? user.initials : null; // Returner initialer, hvis brugeren findes
      })
      .filter(initials => initials !== null); // Filtrer null-værdier
  }

  private calculateMinMaxDates(): void {
    const startDates = this.trialPeriodDays.filter(day => day.datePlannedStartUtc).map(day => moment(day.datePlannedStartUtc));

    const endDates = this.trialPeriodDays.filter(day => day.datePlannedEndUtc).map(day => moment(day.datePlannedEndUtc));

    // Calculate the minimum and maximum dates
    const minStart = moment.min(startDates);
    const maxEnd = moment.max(endDates);

    // Add padding of one week to both ends
    this.minDate = minStart.subtract(1, "weeks").toDate();
    this.maxDate = maxEnd.add(1, "weeks").toDate();
  }

  getLessonTypeName(typeId: number): string {
    const lessonType = this.lessonTypes.find(type => type.id === typeId);
    return lessonType ? lessonType.name : "Ukendt";
  }

  onActionComplete(args: any): void {
    if (args.requestType === "eventChanged") {
      const updatedEvent = args.data[0];

      // Find det originale element i trialPeriodDays og opdater det
      const originalEvent = this.trialPeriodDays.find(day => day.id === updatedEvent.Id);

      if (originalEvent) {
        const newStartDate = moment(updatedEvent.StartTime).toDate();
        const newEndDate = moment(updatedEvent.EndTime).toDate();

        originalEvent.datePlannedStartUtc = moment(newStartDate).utc().format();
        originalEvent.datePlannedEndUtc = moment(newEndDate).utc().format();
        originalEvent.plannedStartDate = newStartDate;
        originalEvent.plannedEndDate = newEndDate;

        // Check if there is already an appointment on the new start date
        const existingAppointment = this.trialPeriodDays.find(day => day.plannedStartDate.toDateString() === newStartDate.toDateString() && day.id !== originalEvent.id);

        if (existingAppointment) {
          // Move all appointments forward by one day
          this.moveAppointmentsForward(originalEvent);
        }
      }

      this.initializeItems();

      // Opdater dataSource ved hjælp af en ny kopi
      this.eventSettings = {
        ...this.eventSettings,
        dataSource: [...this.appointments] // Brug spread-operatoren for at oprette en ny reference
      };

      this.cd.markForCheck();
    }
  }

  onCreate(): void {
    //console.log("Schedule created.");
  }

  onNavigating(event: any): void {
    //console.log("Navigating to", event.currentDate);
  }

  addNewActivity(event: any): void {
    event.cancel = true;
    //console.log("Cell clicked", args);
  }

  onEventClick(event: any): void {
    event.cancel = true;
    const selectedEvent = this.trialPeriodDays.find(day => day.id === event.event.Id);

    this.selectedUserItems = userItems.filter(user => selectedEvent.userIds.includes(user.value));

    if (selectedEvent) {
      this.selectedItem = { ...selectedEvent }; // Lav en kopi for redigering
    }
  }

  onSave(): void {
    if (this.trialPeriod) {
      this.trialPeriod.days = this.trialPeriodDays.map(day => {
        let plannedStartString = null;
        let plannedEndString = null;

        // Kombiner Start Dato og Tid
        if (day.plannedStartDate) {
          const plannedDate = new Date(day.plannedStartDate); // Samme dato for både start og slut
          if (day.plannedStartTime) {
            const [startHours, startMinutes] = day.plannedStartTime.split(":").map(num => parseInt(num, 10));
            plannedDate.setHours(startHours, startMinutes, 0, 0);
            plannedStartString = plannedDate.toISOString(); // Kombiner starttidspunkt
          }

          // Kombiner Slut Tid med samme dato
          if (day.plannedEndTime) {
            const plannedEndDate = new Date(day.plannedStartDate); // Brug samme dato
            const [endHours, endMinutes] = day.plannedEndTime.split(":").map(num => parseInt(num, 10));
            plannedEndDate.setHours(endHours, endMinutes, 0, 0);
            plannedEndString = plannedEndDate.toISOString(); // Kombiner sluttidspunkt
          }
        }

        // Validering: Sluttidspunkt skal være senere end starttidspunkt
        if (plannedStartString && plannedEndString) {
          const plannedStartDate = new Date(plannedStartString);
          const plannedEndDate = new Date(plannedEndString);

          if (plannedEndDate < plannedStartDate) {
            console.error("Error: Sluttidspunkt skal være senere end starttidspunkt.");
            throw new Error("Ugyldigt tidsinterval for en dag i prøveperioden.");
          }
        }

        return {
          ...day,
          userIds: day.userIds.join(","), // Konverter userIds til en kommasepareret streng
          datePlannedStartUtc: plannedStartString,
          datePlannedEndUtc: plannedEndString,
          notes: day.notes,
          activityId: day.activityId
        };
      });

      this.activityService.updateTrialPeriod(this.trialPeriod).subscribe(
        response => {
          this.trialPeriod = response;

          console.log("Trial Period saved or updated successfully:", response);
          this.closeDialog.emit(true);
        },
        error => {
          console.error("Error saving trial period:", error);
        }
      );
    } else {
      console.error("TrialPeriod data is missing.");
    }
  }

  public errorMessages: string[] = [];

  handleSaveDialog(): void {
    this.errorMessages = []; // Nulstil fejlbeskeder

    if (!this.selectedItem) {
      this.errorMessages.push("Ingen element valgt til redigering.");
      return;
    }

    const originalEvent = this.trialPeriodDays.find(day => day.id === this.selectedItem.id);
    if (!originalEvent) {
      this.errorMessages.push("Det valgte element findes ikke.");
      return;
    }

    // Opdater basisoplysninger
    originalEvent.typeId = this.selectedItem.typeId;
    originalEvent.userIds = [...this.selectedItem.userIds];
    originalEvent.notes = this.selectedItem.notes;

    const { plannedStartDate, plannedStartTime, plannedEndTime } = this.selectedItem;

    if (!plannedStartDate || !plannedStartTime || !plannedEndTime) {
      this.errorMessages.push("Startdato, starttid og sluttid skal angives.");
      return;
    }

    // Kombiner dato og tid
    const plannedStart = new Date(plannedStartDate);
    const [startHours, startMinutes] = plannedStartTime.split(":").map(Number);
    plannedStart.setHours(startHours, startMinutes, 0, 0);

    const plannedEnd = new Date(plannedStartDate);
    const [endHours, endMinutes] = plannedEndTime.split(":").map(Number);
    plannedEnd.setHours(endHours, endMinutes, 0, 0);

    // Valider tider
    if (plannedEnd <= plannedStart) {
      this.errorMessages.push("Sluttidspunkt skal være senere end starttidspunkt.");
      return;
    }

    // Opdater originale hændelsesoplysninger
    originalEvent.datePlannedStartUtc = plannedStart.toISOString();
    originalEvent.datePlannedEndUtc = plannedEnd.toISOString();
    originalEvent.plannedStartTime = plannedStartTime;
    originalEvent.plannedEndTime = plannedEndTime;

    // Reinitialiser kalenderdata
    this.initializeItems();
    this.selectedItem = null; // Skjul formularen
    this.cd.markForCheck();
  }

  handleCloseDialog(): void {
    this.selectedItem = null; // Skjul formularen uden at gemme
  }

  handleDeleteDialog(): void {
    if (this.selectedItem) {
      const index = this.trialPeriodDays.findIndex(day => day.id === this.selectedItem.id);
      if (index !== -1) {
        this.trialPeriodDays.splice(index, 1); // Fjern elementet fra listen
        this.initializeItems(); // Opdater listen
        this.selectedItem = null; // Luk dialogen
        this.cd.markForCheck(); // Sørg for at opdatere UI
      }
    }
  }

  onMoreEventsClick(event: any): void {
    // console.log("More events clicked", event);
  }

  onEventRendered(args: EventRenderedArgs): void {
    const typeId = args.data["TypeId"];
    if (typeId) {
      const lessonType = this.lessonTypes.find(type => type.id === typeId);
      if (lessonType) {
        args.element.style.backgroundColor = lessonType.color;
      }
    }
  }

  addAppointment(): void {
    const selectedType = this.lessonTypes.find(type => type.id === this.selectedLessonType);
    if (selectedType) {
      let lastAppointmentOfType: TrialPeriodDay | undefined;
      let lastAppointment: TrialPeriodDay | undefined = this.trialPeriodDays[this.trialPeriodDays.length - 1];

      for (let i = this.trialPeriodDays.length - 1; i >= 0; i--) {
        if (this.trialPeriodDays[i].typeId === this.selectedLessonType) {
          lastAppointmentOfType = this.trialPeriodDays[i];
          break;
        }
      }

      if (!lastAppointmentOfType) {
        lastAppointmentOfType = lastAppointment;
      }

      if (lastAppointmentOfType) {
        const startDate = new Date(lastAppointment.plannedStartDate);
        const endDate = new Date(lastAppointment.plannedEndDate);

        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);

        const newAppointment: TrialPeriodDay = {
          ...lastAppointmentOfType,
          id: -(this.trialPeriodDays.length + 1),
          datePlannedStartUtc: startDate.toISOString(),
          datePlannedEndUtc: endDate.toISOString(),
          plannedStartDate: startDate,
          plannedEndDate: endDate
        };
        this.trialPeriodDays.push(newAppointment);
        this.initializeItems();
        this.cd.markForCheck();
      }
    }
  }

  moveAppointmentsForward(droppedDay: TrialPeriodDay): void {
    const sortedDays = [...this.trialPeriodDays].sort((a, b) => a.plannedStartDate.getTime() - b.plannedStartDate.getTime());
    let nextAvailableDate = new Date(droppedDay.plannedStartDate);
    nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);

    for (const appointment of sortedDays) {
      if (appointment.id !== droppedDay.id && appointment.plannedStartDate >= droppedDay.plannedStartDate) {
        // Spring weekender over
        while (nextAvailableDate.getDay() === 6 || nextAvailableDate.getDay() === 0) {
          nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
        }

        if (appointment.plannedStartDate < nextAvailableDate) {
          // Flyt aftalen til næste ledige dag
          appointment.plannedStartDate = new Date(nextAvailableDate);
          appointment.plannedEndDate = new Date(nextAvailableDate);
          appointment.datePlannedStartUtc = appointment.plannedStartDate.toISOString();
          appointment.datePlannedEndUtc = appointment.plannedEndDate.toISOString();
          // Gå til den næste dag
          nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
        }
      }
    }

    // Reinitialiser kalenderdata og UI
    this.initializeItems();
    this.cd.markForCheck();
  }

  removeEmptyGaps(): void {
    // Sortér aftaler efter startdato
    const sortedDays = [...this.trialPeriodDays].sort((a, b) => a.plannedStartDate.getTime() - b.plannedStartDate.getTime());
    let currentDate = new Date(sortedDays[0].plannedStartDate);

    for (const appointment of sortedDays) {
      // Spring weekender over
      while (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Hvis aftalen ikke er på den nuværende dato, flyt den til den nye dato
      if (appointment.plannedStartDate.getTime() !== currentDate.getTime()) {
        appointment.plannedStartDate = new Date(currentDate);
        appointment.plannedEndDate = new Date(currentDate);
        appointment.datePlannedStartUtc = appointment.plannedStartDate.toISOString();
        appointment.datePlannedEndUtc = appointment.plannedEndDate.toISOString();
      }

      // Ryk til næste dag
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Opdater visningen og data
    this.initializeItems();
    this.cd.markForCheck();
  }
}
