<!-- Status Legend -->
<div class="color-overview-horizontal flex-item color-overview-right">
  <div class="color-overview-content">
    <div *ngFor="let status of statuses" class="color-item">
      <span class="color-box" [style.background-color]="status.title"></span>
      <span class="status-label">{{ status.label }}</span>
    </div>
  </div>
</div>

<!-- Scheduler Table -->
<div class="scheduler-table">
  <table>
    <thead>
      <tr>
        <!-- Uge Kolonne -->
        <th class="week-cell">Uger</th>
        <th *ngFor="let employee of userItems">
          {{ employee?.label || "Ukendt" }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let week of weeks">
        <!-- Uge Nummer -->
        <td class="week-cell week-number">
          <div>Uge</div>
          <div class="large-number">{{ week.weekNumber.split(",")[0].split(" ")[1] }}</div>
          <div>{{ week.weekNumber.split(",")[1].trim() }}</div>
        </td>

        <!-- Ansatte -->
        <td *ngFor="let employee of userItems">
          <div *ngIf="employee">
            <div *ngFor="let trialPeriod of week.trialPeriods">
              <div
                class="appointment"
                *ngIf="trialPeriod.employee === employee.label"
                [style.background-color]="getAppointmentColor(trialPeriod.trialPeriodStatus)"
                (click)="selectProspect(trialPeriod.id)"
              >
                <div class="appointment-content" [title]="getFormattedNotes(trialPeriod?.trialPeriodNotes)">
                  <div>
                    <b>{{ trialPeriod?.brugerNavn }}</b> ({{ trialPeriod?.chrNr }})
                  </div>
                  <div>
                    {{ trialPeriod?.adresse }}
                  </div>
                  <div>{{ trialPeriod?.postNummer }} {{ trialPeriod?.postDistrikt }} {{ trialPeriod?.byNavn }}</div>

                  <div *ngIf="trialPeriod?.contactPhone || trialPeriod?.contactPhone">
                    <span *ngIf="trialPeriod?.contactName"> {{ trialPeriod?.contactName }}</span>
                    <span *ngIf="trialPeriod?.contactPhone"> {{ trialPeriod?.contactPhone }}</span>
                    <span *ngIf="trialPeriod?.contactEmail"> {{ trialPeriod?.contactEmail }}</span>
                  </div>

                  <div *ngIf="trialPeriod?.trialPeriodStartDay || trialPeriod?.trialPeriodStartHour">
                    <span *ngIf="trialPeriod?.trialPeriodStartDay"> Startdag: {{ getDayOfWeekLabel(trialPeriod?.trialPeriodStartDay) }}</span>
                    <span *ngIf="trialPeriod?.trialPeriodStartHour"> Starttid: {{ trialPeriod?.trialPeriodStartHour }}:00</span>
                  </div>

                  <div *ngIf="trialPeriod?.sdSygdomsNiveauKode">
                    <b>Salmonella dublin niveau kode {{ trialPeriod?.sdSygdomsNiveauKode }}</b>
                  </div>

                  <div *ngIf="trialPeriod?.malkesystem">
                    <span *ngIf="trialPeriod.malkesystem.includes('robotter')">Malkesystem: Robotter</span>
                    <span *ngIf="trialPeriod.malkesystem.includes('karrusel')">Malkesystem: Karrusel</span>
                  </div>

                  <div *ngIf="trialPeriod?.trialPeriodNotes" [title]="getFormattedNotes(trialPeriod?.trialPeriodNotes)">
                    <span><b>Bemærkninger:</b> Hold musen over for at se detaljer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
