import { ChangeDetectorRef, Component, HostBinding, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { of } from "rxjs";
import { finalize, switchMap, take } from "rxjs/operators";

import { HttpErrorResponse } from "@angular/common/http";
import { UserEditModel } from "@apiModels/userEditModel";
import { BiNotificationConfig, BiToastNotificationService, NotificationType } from "@core/utility-services/bi-toast-notification.service";
import { BiCountryId, BiLanguageId } from "@enums/BiLanguageAndCountryId";
import { CustomerService } from "@globals/services/customer.service";
import { UserService } from "@globals/services/user.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { BiCustomAnimations } from "@shared/classes/BICustomAnimations";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";

interface MainFormValue {
  email: string;
  name: string;
  phone: string;
  languageId: number;
  countryId: number;
  pwd: string;
  deleted: boolean;
  administrator: boolean;
  primaryFarmId: number;
}

@UntilDestroy()
@Component({
  templateUrl: "./user-edit-main.component.html",
  animations: [BiCustomAnimations.fadeInDown, BiCustomAnimations.fadeIn]
})
export class UserEditMainComponent implements OnInit {
  public user?: UserEditModel;
  public customerId?: number;
  public mainForm: UntypedFormGroup;
  public showFormErrorMessage: boolean;
  public loading = false;
  public errorMessage = "";

  public createOrSave: string;
  public passwordLabel: string;
  public farms: Array<SelectItem> = [];

  @HostBinding("@fadeInDown") get fadeInHost() {
    return true;
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private customerService: CustomerService,
    private notifier: BiToastNotificationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const matrixParams = this.activeRoute.snapshot.paramMap.get("customerId");
    if (matrixParams) {
      this.customerId = +matrixParams;
    }

    this.activeRoute.parent.params
      .pipe(
        untilDestroyed(this),
        take(1),
        switchMap(params => {
          if (!params["id"] || isNaN(+params["id"])) return of(this.userService.initializeUserModel());
          if (+params["id"] === 0) return of(this.userService.initializeUserModel());
          return this.userService.getUser(+params["id"], true);
        }),
        switchMap(user => {
          this.user = user;

          // Brug enten user.customerId eller matrix parameter fra URL
          this.customerId = this.customerId || user.customerId;

          if (this.user.id === 0) {
            this.createOrSave = "Opret";
            this.passwordLabel = "Adgangskode";
          } else {
            this.createOrSave = "Gem";
            this.passwordLabel = "Ny adgangskode";
          }

          return this.customerId ? this.customerService.getFarms(this.customerId) : of([]); // If no customerId, return an empty array
        }),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe({
        next: farms => {
          //console.log("Farms:", farms);

          this.farms = farms.map(farm => ({
            label: farm.name,
            value: farm.id
          }));

          this.farms.unshift({
            label: "Ikke valgt",
            value: 0
          });

          this.initFormGroup();
        },
        error: err => {
          this.errorMessage = "Der opstod en fejl ved hentning af data.";
          console.error("Error:", err);
        }
      });
  }

  private initFormGroup() {
    let farmId = this.user?.primaryFarmId;
    if (!farmId) farmId = this.farms[this.farms.length - 1].value;

    this.mainForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.user?.email ?? "", [Validators.required, Validators.email]),
      name: new UntypedFormControl(this.user?.name ?? "", [Validators.required, Validators.minLength(3)]),
      phone: new UntypedFormControl(this.user?.phone ?? "", [Validators.maxLength(50)]),
      languageId: new UntypedFormControl(this.user?.languageId ?? 1, [Validators.required]),
      countryId: new UntypedFormControl(this.user?.countryId ?? 1, [Validators.required]),
      pwd: new UntypedFormControl(this.user?.pwd ?? "", [Validators.required, Validators.minLength(4)]),
      administrator: new UntypedFormControl(this.user?.administrator ?? false),
      deleted: new UntypedFormControl(this.user?.deleted ?? false),
      primaryFarmId: new UntypedFormControl(this.user?.primaryFarmId ?? farmId)
    });
  }

  // private getPasswordValidators() {
  //   if (this.passwordLabel === "Adgangskode") {
  //     return [Validators.required, Validators.minLength(4)];
  //   } else {
  //     return Validators.minLength(4);
  //   }
  // }

  public get email() {
    return this.mainForm.get("email");
  }

  public get name() {
    return this.mainForm.get("name");
  }

  public get phone() {
    return this.mainForm.get("phone");
  }

  public get languageId() {
    return this.mainForm.get("languageId");
  }

  public get countryId() {
    return this.mainForm.get("countryId");
  }

  public get pwd() {
    return this.mainForm.get("pwd");
  }

  public get administrator() {
    return this.mainForm.get("administrator");
  }

  public get deleted() {
    return this.mainForm.get("deleted");
  }

  public get primaryFarmId() {
    return this.mainForm.get("primaryFarmId");
  }

  private createObjectFromFormValue(formValue: MainFormValue): UserEditModel {
    const returnObject: Partial<UserEditModel> = {
      ...formValue,
      id: 0,
      customerId: this.customerId
    };

    //returnObject.primaryFarmId = this.primaryFarmId.value;

    if (this.user.id !== 0) {
      returnObject.id = this.user.id;
      returnObject.customerId = this.user.customerId;
      returnObject.useHerds = this.user.useHerds;
    } else {
      returnObject.customerId = this.customerService.currentCustomerId;
    }

    return returnObject;
  }

  private checkAndValidateForm() {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }

    this.showFormErrorMessage = false;
    return true;
  }

  public onSaveClicked() {
    this.saveUser();
  }

  public saveUser() {
    if (!this.checkAndValidateForm()) return;

    const stay = this.user.id === 0;

    const formValue = this.mainForm.value as MainFormValue;

    const newOrUpdatedObject = this.createObjectFromFormValue(formValue) as UserEditModel;

 
    this.userService.updateUser(newOrUpdatedObject).subscribe({
      next: userId => {
        if (this.user.id === 0) this.user.id = userId;

        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev gemt"
        });

        return this.onSaveComplete(stay);
      },
      //error: err => (this.errorMessage = err)

      error: (err: HttpErrorResponse) => {
        // Notify User

        this.messageService.add({
          life: 5000,
          severity: "error",
          summary: "Der skete en fejl",
          detail: err.error.errorMessage
        });
      }
    });
  }

  onSaveComplete(stay: boolean): void {
    // Reset the form to clear the flags
    this.mainForm.reset();
    // if (stay) this.router.navigate(["/superadmin/users", this.user.id, "main"]);
    // else this.router.navigate(["/superadmin/users"]);
    this.router.navigate(["/superadmin/customers", this.customerId, "users"]);
  }

  confirmBrugerMail(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: "Er du sikker på du vil sende en oprettelses mail?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.brugerMail();
        //confirm action
      },
      reject: () => {
        //reject action
      }
    });
  }

  public brugerMail() {
    this.userService
      .sendNewUserEmail(this.user.id)
      .pipe(
        untilDestroyed(this),
        take(1),
        finalize(() => console.log("sent"))
      )
      .subscribe(
        () => {
          this.notifier.createNotification(new BiNotificationConfig(undefined, this.translate.instant("shared.EmailSent")));
          // this.onEmailSent();
        },
        err => {
          this.notifier.createNotification(new BiNotificationConfig(NotificationType.ERROR, JSON.stringify(err)));
        }
      );
  }

  public languages: Array<SelectItem> = [
    // { value: null, label: "Vælg sprog" },
    { value: BiLanguageId.DK, label: "Dansk" },
    // { value: BiLanguageId.SE, label: "Svensk" },
    { value: BiLanguageId.EN, label: "Engelsk" }
    // { value: BiLanguageId.FI, label: "Finsk" },
    // { value: BiLanguageId.NO, label: "Norsk" }
  ];

  public countries: Array<SelectItem> = [
    // { value: null, label: "Vælg land" },
    { value: BiCountryId.DK, label: "Danmark" }
    // { value: BiCountryId.SE, label: "Sverige" },
    // { value: BiCountryId.FI, label: "Finland" },
    // { value: BiCountryId.NO, label: "Norge" }
  ];

  public janej: Array<SelectItem> = [
    { value: true, label: "Ja" },
    { value: false, label: "Nej" }
  ];

  getFormValidationErrors() {
    console.log("%c ==>> Validation Errors: ", "color: red; font-weight: bold; font-size:25px;");
    let totalErrors = 0;
    Object.keys(this.mainForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.mainForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
        });
      }
    });
    console.log("Number of errors: ", totalErrors);
  }
}
