<div>
  <div *ngIf="tasks" class="scheduler">
    <ng-container *ngFor="let weekYearId of getWeekYears()">
      <table>
        <thead>
          <tr>
            <th>Medarbejder</th>
            <th *ngFor="let day of days; let i = index">
              {{ day }} uge {{ getWeek(weekYearId) }} - {{ getYear(weekYearId) }}
              <span *ngIf="i === 0" class="meeting-note">Personale møde kl 16</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let employee of userItems">
            <tr>
              <td>{{ employee.label }}</td>
              <td
                *ngFor="let day of days; let i = index"
                [class.drag-over]="draggingTask"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave()"
                (drop)="onDrop($event, employee.value, getYear(weekYearId), getWeek(weekYearId), i + 1, weekYearId)"
                (click)="addTask(employee.value, getYear(weekYearId), getWeek(weekYearId), i + 1, $event)"
              >
                <div class="task-container">
                  <div
                    *ngFor="let task of getTasksForEmployeeOnDay(employee.value, getYear(weekYearId), getWeek(weekYearId), i + 1)"
                    class="task"
                    [ngClass]="getTaskCssClass(task)"
                    data-id="{{ task.id }}"
                    (click)="openEditDialog(task); $event.stopPropagation()"
                    [draggable]="!isEditing"
                    (dragstart)="startDrag($event, task)"
                  >
                    <span class="drag-handle" *ngIf="task.userIds.length === 1 && task.days.length === 1">⋮⋮</span>
                    <span class="task-title">{{ task.title }}</span>
                    <span *ngIf="task.userIds.length > 1" class="multi-user-indicator">👥 </span>
                    <span *ngIf="task.days?.length > 1" class="multi-days-indicator">
                      <i class="pi pi-calendar-plus"></i>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>

  <p-dialog
    *ngIf="selectedTask"
    [(visible)]="displayTaskDialog"
    modal="true"
    [closable]="true"
    [style]="{ width: '700px', minHeight: '500px' }"
    header="Rediger aktivitet"
    (onHide)="closeDialog(false)"
  >
    <div class="p-fluid">
      <!-- Title Field -->
      <div class="p-field">
        <label for="title">Aktivitet</label>
        <input id="title" type="text" [(ngModel)]="selectedTask.title" pInputText (keydown.enter)="saveTask()" />
        <!-- Error message for empty title -->
        <small *ngIf="!selectedTask.title" class="p-error">Aktivitet skal angives</small>
      </div>

      <!-- Users Field -->
      <div class="p-field">
        <label for="users">Brugere</label>
        <div class="day-selector">
          <p-button *ngFor="let user of userItems" [label]="user.label" [severity]="selectedTask.userIds?.includes(user.value) ? 'primary' : 'secondary'" (click)="toggleUserSelection(user.value)">
          </p-button>
        </div>
        <!-- Error message for no users selected -->
        <small *ngIf="selectedTask.userIds?.length === 0" class="p-error">Vælg mindst 1 person</small>
      </div>

      <!-- Days Field -->
      <div class="p-field">
        <label for="day">Days</label>
        <div class="day-selector">
          <p-button *ngFor="let day of days; let i = index" [label]="day" [severity]="selectedTask.days?.includes(i + 1) ? 'primary' : 'secondary'" (click)="toggleDaySelection(i + 1)"> </p-button>
        </div>
        <!-- Error message for no days selected -->
        <small *ngIf="selectedTask.days?.length === 0" class="p-error">Vælg midst 1 dag</small>
      </div>

      <!-- Week Field -->
      <div class="p-field">
        <label for="week">Week</label>
        <input id="week" type="number" [(ngModel)]="selectedTask.week" pInputText [max]="53" [min]="1" (keydown.enter)="saveTask()" />
        <!-- Error message for empty week -->
        <small *ngIf="!selectedTask.week" class="p-error">Uge skal udfyldes</small>
      </div>
    </div>

    <p-footer>
      <div class="button-container">
        <!-- <button *ngIf="selectedTask.id === 0" pButton type="button" label="Tilføj" icon="pi pi-check" class="p-button-success" (keydown.enter)="saveTask()" (click)="saveTask()"></button>
        <button *ngIf="selectedTask.id === 0" pButton type="button" label="Fortryd" icon="pi pi-times" class="p-button-danger" (click)="closeDialog()"></button> -->

        <button
          [disabled]="selectedTask.title.length === 0 || selectedTask.days.length === 0 || selectedTask.userIds.length === 0 || !selectedTask.week"
          pButton
          type="button"
          label="Gem"
          icon="pi pi-times"
          (click)="saveTask()"
        ></button>
        <!-- <button pButton type="button" label="Fortryd" icon="pi pi-times" class="p-button-danger" (click)="closeDialog()"></button> -->
        <button *ngIf="selectedTask.id !== 0" pButton type="button" icon="pi pi-trash" [label]="'Slet'" class="p-button-danger" (click)="deleteTask(selectedTask)"></button>
      </div>
    </p-footer>
  </p-dialog>
</div>
