import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MaterialDto } from "@apiModels/materialDto";
import { MaterialStockDto } from "@apiModels/materialStockDto";
import { InventoryService } from "@globals/services/inventory.service";
import { TableColumnPrimeNgExt } from "@shared/interfaces-and-enums/TableColumnPrimeNgExt";
import { PrimeNgTableColumn, PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { userInfos } from "../../../activities/shared-data";

@Component({
  selector: "app-inventory-list",
  templateUrl: "./inventory-list.component.html",
  styleUrls: ["./inventory-list.component.scss"]
})
export class InventoryListComponent implements OnInit {
  @ViewChild("table") table: Table;
  @ViewChild("quantityInput") quantityInput: ElementRef;
  loading = true;
  inventoryData: MaterialStockDto[] = [];
  materials: MaterialDto[] = [];
  userSelectItems: SelectItem[] = [];
  selectedInventoryItem: MaterialStockDto | null = null;
  dialogVisible: boolean = false;

  selectedUserItem: SelectItem | null = null;
  selectedMaterial: MaterialDto | null = null;
  selectedOperation: string | null = null;
  selectedQuantity: number | null = null;
  selectedComment: string | null = null;

  materialFilterItems: SelectItem[] = [];
  selectedMaterialFilterItem: SelectItem = null;
  userFilterSelectItems: SelectItem[] = [];
  selectedUserFilterItem: SelectItem = null;

  public userInfos = userInfos;

  // Dropdown-valgmuligheder for operationer
  operationOptions = [
    { label: "Indkøb", value: "purchase" },
    { label: "Forbrug", value: "consumption" }
  ];

  public columns: Array<TableColumnPrimeNgExt> = [
    { field: "materialName", header: "Materiale" },
    { field: "userName", header: "Lager placering" },
    { field: "quantity", header: "Antal" }
  ];

  public globalFilterFields: Array<string> = ["name", " description", "unit"];

  filteredInventoryData: MaterialStockDto[] = [];

  constructor(private inventoryService: InventoryService) {}

  ngOnInit() {
    this.initializeInventoryData();
    this.loadMaterials();
    this.loadUserSelectItems(); // Load user dropdown options
  }

  onRowSelect(event) {
    this.dialogVisible = true;
  }

  // Hent lagerbeholdning
  initializeInventoryData() {
    this.loading = true;
    this.inventoryService
      .getMaterialStock(1)
      .pipe(
        catchError(error => {
          console.error("Fejl ved indlæsning af lagerdata", error);
          return of([]); // Returnér tom liste ved fejl
        }),
        finalize(() => {
          this.loading = false;
          this.filteredInventoryData = this.inventoryData; // Set filtered data to full inventory initially
        })
      )
      .subscribe(data => {
        this.inventoryData = data; // Assign the data to the array
      });
  }

  // Hent materialeliste til dropdown
  loadMaterials() {
    this.inventoryService
      .getMaterials()
      .pipe(
        catchError(error => {
          console.error("Fejl ved indlæsning af materialeliste", error);
          return of([]); // Returnér tom liste ved fejl
        })
      )
      .subscribe(data => {
        this.materials = data; // Assign the data to the array

        // Include the "Alle" option at the beginning of the list
        this.materialFilterItems = [
          { label: "Alle", value: null }, // "Alle" option with null value
          ...this.materials.map(material => ({
            label: material?.name || "Ukendt", // Use material.name (or any field you want as label)
            value: material // Assign the whole material object as the value
          }))
        ];

        this.selectedMaterialFilterItem = this.materialFilterItems[0];
      });
  }

  // Helper function to generate user select items
  private generateUserSelectItems(): { value: number | null; label: string }[] {
    return Object.values(this.userInfos).map(user => ({
      value: user.id,
      label: user.fullName
    }));
  }

  // Load user select items for the dropdown
  loadUserSelectItems() {
    // Generate user select items
    const userItems = this.generateUserSelectItems();

    // Set user select items (for regular use)
    this.userSelectItems = userItems;

    // Set user filter select items (with "Alle" as the first option)
    this.userFilterSelectItems = [{ value: null, label: "Alle" }, ...userItems];

    // Set default selected item to "Alle"
    this.selectedUserFilterItem = this.userFilterSelectItems[0];
  }

  // Åbn dialog til lageropdatering
  openDialog() {
    this.dialogVisible = true;
    this.selectedUserItem = null;
    this.selectedMaterial = null;
    this.selectedOperation = null;
    this.selectedQuantity = null;
  }

  // Luk dialog
  closeDialog() {
    this.dialogVisible = false;
  }

  // Gem lageropdatering
  saveInventoryUpdate() {
    if (!this.selectedMaterial || !this.selectedUserItem || !this.selectedOperation || !this.selectedQuantity) {
      console.error("Udfyld alle felter");
      return;
    }

    const materialStock: MaterialStockDto = {
      id: 0,
      materialId: this.selectedMaterial.id,
      userId: this.selectedUserItem.value,
      quantity: this.selectedQuantity,
      operation: this.selectedOperation,
      comment: this.selectedComment
    };

    this.inventoryService.createOrUpdateMaterialStock(materialStock).subscribe({
      next: response => {
        console.log("Lager opdateret", response);
        this.closeDialog();
        this.initializeInventoryData(); // Genindlæs lagerdata
      },
      error: error => {
        console.error("Fejl ved lageropdatering", error);
      }
    });
  }

  openDialogWithPreset(rowData: MaterialStockDto | null, operation: string) {
    this.dialogVisible = true;

    if (rowData) {
      // Forudfyld værdier fra eksisterende data
      this.selectedMaterial = {
        id: rowData.materialId,
        name: rowData.materialName
      } as MaterialDto;

      this.selectedUserItem = {
        value: rowData.userId,
        label: rowData.userName
      } as SelectItem;
    } else {
      // Ryd værdier, da der ikke er valgt en specifik række
      this.selectedMaterial = null;
      this.selectedUserItem = null;
    }

    this.selectedOperation = operation;
    this.selectedQuantity = null; // Ryd feltet for mængde

    // Vent på at dialogen åbner, og sæt fokus på feltet
    setTimeout(() => {
      this.quantityInput?.nativeElement.focus();
    }, 200);
  }

  focusQuantityInput() {
    // Sikrer fokus på mængde-inputfeltet
    setTimeout(() => {
      this.quantityInput?.nativeElement.focus();
    }, 200);
  }

  public exportToExcel() {
    const exportableColumns = this.columns.filter(col => !col.noExport);
    PrimeNgUtilities.exportCSV(this.table, exportableColumns as PrimeNgTableColumn[], { bom: true });
  }

  // Filter inventory data based on selected user
  applyFilters() {
    let filteredData = this.inventoryData;

    // Apply the user filter if selected
    if (this.selectedUserFilterItem && this.selectedUserFilterItem.value) {
      filteredData = filteredData.filter(item => item.userId === this.selectedUserFilterItem.value);
    }

    // Apply the material filter if selected
    if (this.selectedMaterialFilterItem && this.selectedMaterialFilterItem.value) {
      filteredData = filteredData.filter(item => item.materialId === this.selectedMaterialFilterItem.value.id); // Assuming material object has `id`
    }

    // Update the filtered inventory data
    this.filteredInventoryData = filteredData;
  }
}
